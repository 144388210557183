import { Button, Modal } from 'antd';
import { Component } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Rules } from '../../../../rbacRules';
import Network from '../../../../utils/network';
import { IMission, IMissionEdit } from '../../../../utils/types/customerTypes';
import { RouterProps, ValidateFile } from '../../../../utils/types/generalTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import Can from '../../../common/general/can';
import EditMissionForm from './editMissionForm';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps {
    mission?: IMissionEdit;
    onCancel: () => void;
    onOk: (mission: IMission) => void;
}

interface State {
    mission?: IMissionEdit;
    files: {
        filesToValidate: ValidateFile[];
        imagesToValidate: ValidateFile[];
        ruleId: number;
    }[]
}

export class EditMissionModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            files: [],
        };
    }

    onCancel = () => this.props.onCancel();

    onDone = () => {
        const { mission } = this.state;
        const { intl } = this.props;
        if (mission && mission.mandateId) {
            Network.updateMission(mission, mission.mandateId).then(
                (response) => {
                    showNotification(intl.formatMessage({ defaultMessage: 'The mission has been successfully created' }), "success");
                    this.state.files.forEach(f => {
                        const rule = response.data.rules ? response.data.directRules?.find(r => r.oldRuleId == f.ruleId) : undefined
                        if (rule && rule.template && rule.template.id)
                            this.validateFiles(rule?.template.id, f.filesToValidate, f.imagesToValidate)
                    })
                    this.props.onOk(response.data);
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the mission' }), "error");
                }
            );
        }
    };
    validateFiles = async (templateId: number, filesToValidate:ValidateFile[], imagesToValidate:ValidateFile[]) => {
        if (templateId != null) {
            if (filesToValidate.length > 0) {
                await Network.validateTemplateFiles(templateId, filesToValidate).then(
                    (response) => {
                        if (response.error || !response.data) {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the files' }), "warning");
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the files' }), "warning");
                    }
                );
            }
            if (imagesToValidate.length > 0) {
                Network.validateTemplateFiles(templateId, imagesToValidate).then(
                    (response) => {
                        if (response.error === false && response.data) {
                            this.setState({ files: [] });
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the images' }), "warning");
                    }
                );
            }
        }
    };

    missionReadyToSave = (mission?: IMissionEdit) => {
        if (!isEqual(this.state.mission, mission)) {
            this.setState({ mission });
        }
    };


    render() {
        const { mission } = this.props;
        const isEdit = mission && mission.id;
        return (
            <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                <Modal
                    centered
                    className="__availability-modal"
                    open={mission !== undefined}
                    title={isEdit ? <FormattedMessage defaultMessage={'Edit the mission {title}'} values={{ title: mission.title }} /> : <FormattedMessage defaultMessage={'Add a mission'} />}
                    width={"1250px"}
                    onCancel={this.onCancel}
                    destroyOnClose={true}
                    footer={[
                        <Button key="mission-modal-cancel-button" type="dashed" onClick={this.onCancel}>
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <Button key="mission-modal-save-button" disabled={this.state.mission === undefined} type="primary" onClick={this.onDone}>
                            {isEdit ? <FormattedMessage defaultMessage={'Save'} /> : <FormattedMessage defaultMessage={'Add'} />}
                        </Button>
                    ]}>
                    {
                        mission ?
                            <EditMissionForm 
                                missionReadyToSave={this.missionReadyToSave} 
                                mission={mission} 
                                files={this.state.files} 
                                setFiles={(files: {
                                    filesToValidate: ValidateFile[];
                                    imagesToValidate: ValidateFile[];
                                    ruleId: number;
                                }[]) => this.setState({files})}
                            />
                            : null
                    }
                </Modal>
            </Can>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    missions: state.customerManagement.missions,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
});

const connector = connect(mapStateToProps);

export default withRouter(connector(injectIntl(EditMissionModal)));