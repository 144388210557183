
import { Badge } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { BLUE_COLOR } from '../../../../utils/constants';
import FAIcon from '../../FAIcon';
import './newFeature.css';

interface NewFeatureProps {
    children: React.ReactNode;
}

const NewFeature: React.FC<NewFeatureProps> = ({ children }) => {
    const intl = useIntl();
    return (
        <Badge offset={[3, 0]} className='new-feature-badge' count={<FAIcon className='blink_me' prefix='fad' name="seal-exclamation" title={intl.formatMessage({ defaultMessage: 'New feature' })} color={BLUE_COLOR} size="sm" />}>
            {children}
        </Badge>
    )
};

export default NewFeature;