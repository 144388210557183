import { Col, Row, Select } from 'antd';
import React from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Privileges } from '../../../privileges';
import { changeUsers } from '../../../store/actions/teamManagement';
import Network from '../../../utils/network';
import { usersColumns } from '../../../utils/tableUtils';
import { RouterProps, StaffType, User, UserSummary } from '../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../../utils/types/storeTypes';
import { checkPrivilege, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import TableTransfer from '../../common/fields/tableTransfer';
import Card from '../../common/general/card';


interface IProps {
    onEdit: () => void;
}
type ReduxProps = ConnectedProps<typeof connector>;

type Props = IProps & RouterProps & TeamManagementDispatchProps & IntlProps & ReduxProps;

interface State {
    groupActiveUsers: UserSummary[];
    newGroupAdminIds: number[];
    searchedUsers: UserSummary[] | undefined;
    groupUsersLoading: boolean;
    usersLoading: boolean;
    nameLoading: boolean;
    isEditingName: boolean;
    adminsLoading: boolean;
    isEditingAdmins: boolean;
    name?: string;
    // selectedUsers: number[]; //store ids
    editGroup: boolean;
    nameGroupEdit: string;
    loadingGroupEdit: boolean;
    selectedGroupUsers: number[];
    staffTypes: StaffType[];
    selectedStaffTypes: StaffType[];
    targetKeys: any;
}

/**
 * Component that represent the group's informations page
 */
class EditGroupTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            groupActiveUsers: [],
            searchedUsers: undefined,
            groupUsersLoading: false,
            usersLoading: false,
            nameLoading: false,
            isEditingName: false,
            adminsLoading: false,
            isEditingAdmins: false,
            editGroup: false,
            nameGroupEdit: '',
            loadingGroupEdit: false,
            selectedGroupUsers: [],
            staffTypes: [],
            selectedStaffTypes: [],
            targetKeys: undefined,
            newGroupAdminIds: []
        };

    }




    componentDidMount() {
        if (!this.props.usersLoading && this.props.users.length === 0) this.getUsers();

        Network.getStaffType().then(
            response => {
                this.setState({ staffTypes: response });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the abilities' }), "error")
        );
    }

    componentDidUpdate() {
        if (this.props.groupsUsers !== undefined && !isEqual(this.state.groupActiveUsers, this.props.groupsUsers)) {
            let activeUsers: UserSummary[] = [];
            activeUsers = [...this.props.groupsUsers];
            this.setState({ groupActiveUsers: activeUsers });
        }
    }

    /**
     * Get all users
     */
    getUsers = (): void => {
        this.setState({ groupUsersLoading: true });
        Network.getAllUsers().then(
            response => {
                this.setState({ groupUsersLoading: false });
                this.props.changeUsers!(response);
            },
            () => {
                this.setState({ groupUsersLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning");
            }
        );
    };

    /**
     * Called when the group name changed
     * @param event the triggered event
     */
    onChangeName = (event: InputFieldOnChangeEvent) => this.setState({ name: event.target.value });


    /**
     * Start editing group name
     */
    startEditingName = () => {
        this.setState({ isEditingName: true, name: this.props.group?.name });
    };

    /**
     * Cancel editing group name
     */
    cancelEditingName = () => {
        this.setState({ isEditingName: false, name: undefined });
    };

    /**
     * Start editing group name
     */
    startEditingAdmins = () => {
        this.setState({ isEditingAdmins: true, newGroupAdminIds: [...this.state.groupActiveUsers.filter(g => g.is_admin).map(g => g.id)] });

    };

    /**
     * Cancel editing group name
     */
    cancelEditingAdmins = () => {
        this.setState({ isEditingAdmins: false, newGroupAdminIds: [] });
    };

    /**
     * Handle the modification of group's name 
     */
    handleChangeName = () => {
        this.setState({ nameLoading: true });
        Network.updateGroupName(this.props.group!.id!, this.state.name || '').then(
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The group has been successfully updated' }), "success");
                this.props.onEdit();
                this.setState({ nameLoading: false, isEditingName: false });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the group' }), "warning");
                this.setState({ nameLoading: false, isEditingName: false });
            }
        );
    };

    /**
     * Handle the modification of group's name 
     */
    handleChangeAdmins = () => {
        this.setState({ adminsLoading: true });
        Network.updateGroupAdmins(this.props.group!.id!, this.state.newGroupAdminIds).then(
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The managers have been successfully updated' }), "success");
                this.props.onEdit();
                this.setState({ adminsLoading: false });
                this.cancelEditingAdmins();
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the managers' }), "warning");
                this.setState({ adminsLoading: false });
            }
        );
    };

    /**
     * Delete the group
     */
    deleteGroup = () => {
        Network.deleteGroup(this.props.group!.id!).then(
            () => this.props.history.replace({ pathname: `/${this.props.match.params.lang}/team-management/list/groups`, state: { successMessage: this.props.intl.formatMessage({ defaultMessage: 'The group has been successfully deleted' }) } }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the group' }), "warning"),
        );
    };

    onChange = (nextTargetKeys: number[]) => {
        const newUsers = this.props.users.filter((u: User) => nextTargetKeys.find((id: number) => u.id === id));

        Network.updateGroupUsers(this.props.group!.id!, this.props.groupsUsers!, newUsers).then(
            () => {
                this.props.onEdit();
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The members of the group {group} have been successfully updated' }, { group: this.props.group?.name }), "success");
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the group' }), "warning")
        );
    };



    render() {
        const { nameLoading, isEditingName, adminsLoading, isEditingAdmins, groupUsersLoading, groupActiveUsers, newGroupAdminIds } = this.state;
        const { isSmartphone, intl } = this.props;
        const groupAdmins = groupActiveUsers.filter(u => u.is_admin);
        const isCrm = checkPrivilege(Privileges.CRM.Visit, this.props.currentUser);
        const users = this.props.users.filter(u => u.visible !== false);
        return (
            <div className="team-create-group-parent">
                <Card
                    className="team-create-group-table-card"
                    title={<FormattedMessage defaultMessage={'General informations'} />}
                    icon={<FAIcon prefix='fad' name='users' />}
                    headerElements={[
                        !isCrm &&
                        <DeleteButton
                            small
                            key="delete-group-button"
                            style={{ marginLeft: '5px' }}
                            text={<FormattedMessage defaultMessage={'This action will delete the group and all its periods and exclusions (but not its users). Would you like to continue?'} />}
                            onConfirm={this.deleteGroup}
                            cancelText={intl.formatMessage({ defaultMessage: 'No' })}
                            okText={intl.formatMessage({ defaultMessage: 'Yes' })}
                            placement="bottomLeft" />
                    ]
                    }
                >
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }}>
                            <b><FormattedMessage defaultMessage={'Group name'} />{':'}</b>
                            {
                                isEditingName ?
                                    <div className="__single-line">
                                        <InputField
                                            className='__single-line-element'
                                            disabled={nameLoading}
                                            onChange={this.onChangeName}
                                            value={this.state.name}
                                            onPressEnter={this.handleChangeName}
                                            placeholder={intl.formatMessage({ defaultMessage: 'Group name' })} />
                                        <CircleButton
                                            className='__single-line-element'
                                            loading={nameLoading}
                                            small={true}
                                            title={intl.formatMessage({ defaultMessage: 'Save' })}
                                            icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                            onClick={this.handleChangeName} />
                                        <CircleButton
                                            className='__single-line-element'
                                            loading={nameLoading}
                                            small={true}
                                            title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                            icon={<FAIcon prefix='far' name='xmark' />}
                                            onClick={this.cancelEditingName} />
                                    </div>
                                    :
                                    <div className="__single-line">
                                        <span className='__single-line-element'>{this.props.group?.name}</span>
                                        {
                                            !isCrm &&
                                            <FAIcon prefix='fad' name='pencil'
                                                className='__single-line-element'
                                                title={intl.formatMessage({ defaultMessage: 'Edit name' })}
                                                style={isEditingAdmins || isEditingName ? { "cursor": "default", opacity: "0.6" } : { cursor: 'pointer' }}
                                                onClick={() => !isEditingAdmins && !isEditingName && this.startEditingName()} />
                                        }
                                    </div>
                            }
                        </Col>

                        <Col xs={{ span: 24 }}>
                            <b><FormattedMessage defaultMessage={'Group administrators'} />{':'}</b>
                            {
                                isEditingAdmins ?
                                    <div className="__single-line">
                                        <Select
                                            className='__single-line-element'
                                            loading={adminsLoading}
                                            disabled={adminsLoading}
                                            mode="multiple"
                                            maxTagCount="responsive"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Assign managers'} />}
                                            style={isSmartphone ? { flex: '1' } : { width: '44%' }}
                                            onChange={(e) => {
                                                this.setState({ newGroupAdminIds: e });
                                            }}
                                            value={newGroupAdminIds}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                        >
                                            {
                                                groupActiveUsers.length > 0 &&
                                                groupActiveUsers.map((g) => {
                                                    return <Select.Option label={`${g.first_name} ${g.last_name}`} value={g.id} key={"group-admin-" + g.id}>{`${g.first_name} ${g.last_name}`}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                        <CircleButton
                                            className='__single-line-element'
                                            loading={adminsLoading}
                                            small={true}
                                            title={intl.formatMessage({ defaultMessage: 'Save' })}
                                            icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                            onClick={this.handleChangeAdmins} />
                                        <CircleButton
                                            className='__single-line-element'
                                            loading={adminsLoading}
                                            small={true}
                                            title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                            icon={<FAIcon prefix='far' name='xmark' />}
                                            onClick={this.cancelEditingAdmins} />
                                    </div>
                                    :
                                    <div className="__single-line">
                                        <span className='__single-line-element'>{groupAdmins.length === 0 ? <FormattedMessage defaultMessage={'None'} /> : groupAdmins.map(m => `${m.first_name} ${m.last_name}`).join(", ")}</span>
                                        <FAIcon prefix='fad' name='pencil'
                                            className='__single-line-element'
                                            title={intl.formatMessage({ defaultMessage: 'Edit managers' })}
                                            style={isEditingAdmins || isEditingName ? { "cursor": "default", opacity: "0.6" } : { cursor: 'pointer' }}
                                            onClick={() => !isEditingAdmins && !isEditingName && this.startEditingAdmins()} />
                                    </div>
                            }
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <TableTransfer
                                disabled={isEditingAdmins || isEditingName || isCrm}
                                loading={groupUsersLoading}
                                className={"__group-member-transfert"}
                                titles={[
                                    <span key={`editgrouptab-TableTransfer-users`} style={{ textTransform: "uppercase", fontSize: "93%" }}><FormattedMessage defaultMessage={'Available users'} /></span>,
                                    <span key={`editgrouptab-TableTransfer-members-of-groups`} style={{ textTransform: "uppercase", fontWeight: 'bold', fontSize: "95%" }}><FormattedMessage defaultMessage={'Group members'} /></span>
                                ]}
                                rowKey={(item: any) => item.id}
                                dataSource={users}
                                columns={usersColumns(intl, isSmartphone).filter(c => c.key !== "contact.mobile" && c.key !== 'active' && c.key !== "username" && c.key !== "role" && c.key !== 'email' && (this.props.company && ['read-only', 'read-write'].includes(this.props.company.userCodeDisplayAuthorization) || c.key !== 'code'))}
                                targetKeys={groupActiveUsers.map(u => u.id)}
                                onChange={this.onChange}
                                filterOption={(inputValue, item) => `${item.last_name}${item.first_name}`.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
                            />
                        </Col>
                    </Row>
                </Card>
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
});

const mapStateToProps = (state: ApplicationState) => ({
    usersLoading: state.teamManagement.usersLoading,
    users: state.teamManagement.users,
    group: state.teamManagement.group,
    groupsUsers: state.teamManagement.groupsUsers,
    isSmartphone: state.window.isSmartphone,
    currentUser: state.user.currentUser,
    company: state.user.company
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(EditGroupTab)));