import { DatePicker, Divider, Spin } from 'antd/';
import moment, { Moment } from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import getFormat from '../../../../utils/Lang';
import FAIcon from '../../FAIcon';
import SpaceContent from '../../general/spaceContent';
import CircleButton from '../circleButton';

import { IntlProps } from '../../../app/LanguageProvider';
import './amazingDatePicker.css';


interface Props extends IntlProps {
    disabled?: boolean;
    loadingData?: boolean;

    controlled?: {
        valueFrom: Moment;
        valueTo?: Moment;
        onChange: (from: Moment, to: Moment | null, pickerType?: PickerModeType) => void;
    }

    initialPickerType?: PickerModeType;
    initialManualMode?: boolean;

    selectByDay?: boolean;
    selectByWeek?: boolean;
    selectByMonth?: boolean;
    selectByYear?: boolean;
    selectByManual?: boolean;
    disableFuture?: boolean;
}

export const PickerMode = {
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',
} as const;

export type PickerModeType = typeof PickerMode[keyof typeof PickerMode];

interface State {
    internalDateFrom: Moment;
    internalDateTo: Moment | null;
    pickerType?: PickerModeType;
    manualMode: boolean;
}

/**
 * Component that represent a circle button
 */
class AmazingDatePicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            internalDateFrom: moment(),
            internalDateTo: moment(),
            pickerType: this.props.initialPickerType,
            manualMode: this.props.initialManualMode ?? false,
        }
    }

    disableFutureDates = (current: Moment) => current.isAfter(moment(), 'day');

    onChange = (from: Moment, to: Moment | null) => this.setState({ internalDateFrom: from, internalDateTo: to });

    onChangeDate = (date: Moment | null) => {
        const { pickerType, onChange } = this.getCalendarData();

        const { from, to } = this.calculateDateRange(date || moment(), pickerType);
        onChange(from, to, pickerType);
    };

    onChangeDates = (dates: [Moment | null, Moment | null] | null) => {
        if (!dates || !dates[0] || !dates[1] || dates[0].isAfter(dates[1], "days")) return;
        const { pickerType, onChange } = this.getCalendarData();

        onChange(dates[0], dates[1], pickerType);
    };

    nextDate = () => {
        const { pickerType, onChange, valueFrom } = this.getCalendarData();

        const nextDate = (valueFrom || moment()).add(1, pickerType ?? "day");
        const { from, to } = this.calculateDateRange(nextDate, pickerType);

        onChange(from, to, pickerType);
    };

    previousDate = () => {
        const { pickerType, onChange, valueFrom } = this.getCalendarData();

        const previousDate = (valueFrom || moment()).subtract(1, pickerType ?? "day");
        const { from, to } = this.calculateDateRange(previousDate, pickerType);
        onChange(from, to, pickerType);
    };

    calculateDateRange = (baseDate: Moment, pickerType?: PickerModeType) => {
        const { disableFuture } = this.props;

        let from = baseDate.clone();
        let to = baseDate.clone();

        if (pickerType) {
            from = from.startOf(pickerType);
            to = to.endOf(pickerType);
        }

        if (disableFuture) {
            if (this.disableFutureDates(from)) from = moment()
            if (this.disableFutureDates(to)) to = moment()
        }

        if (from.isAfter(to)) from = to.clone();
        return { from, to };
    };

    getCalendarData = () => {
        const { controlled } = this.props;
        const { pickerType: statePickerType, internalDateFrom, internalDateTo } = this.state;

        return {
            pickerType: statePickerType,
            onChange: controlled?.onChange ?? this.onChange,
            valueFrom: controlled?.valueFrom.clone() ?? internalDateFrom?.clone(),
            valueTo: controlled?.valueTo?.clone() ?? internalDateTo?.clone(),
        };
    };

    onChangePickerType = (value?: PickerModeType) => {
        this.setState({ pickerType: value, manualMode: false }, () => this.onChangeDate(this.props.controlled ? this.props.controlled.valueFrom : this.state.internalDateFrom));
    }

    weekFormat = (value: Moment, pickerType?: PickerModeType) => {
        switch (pickerType) {
            case PickerMode.WEEK:
                return `${value.startOf("week").format(getFormat("DAY_SHORT_AND_MONTH_HALF"))} - ${value.endOf("week").format(getFormat("DAY_SHORT_AND_MONTH_HALF"))}`;
            case PickerMode.MONTH:
                return value.format(getFormat("MONTH_AND_YEAR"));
            case PickerMode.YEAR:
                return value.format(getFormat("YEAR"));
            default:
                return value.format(getFormat("DATE"));
        }
    };

    render() {
        const { loadingData, disabled, selectByDay, selectByWeek, selectByMonth, selectByYear, selectByManual, disableFuture, intl } = this.props;
        const { manualMode } = this.state;
        const { pickerType, valueFrom, valueTo } = this.getCalendarData();
        const atLeastOneSelect = selectByDay || selectByWeek || selectByMonth || selectByYear;

        const footer = (mode: any) => (
            <SpaceContent direction='vertical' space={6}>
                <SpaceContent style={{ height: '33px' }} align='center'>
                    {
                        manualMode ?
                            null
                            :
                            <CircleButton
                                small
                                disabled={disabled || valueFrom?.isSame(moment(), pickerType ?? 'day')}
                                icon={<FAIcon prefix='fad' name='house' />}
                                title={intl.formatMessage({ defaultMessage: 'Today' })}
                                onClick={() => this.onChangeDate(moment())}
                            />
                    }
                    {
                        atLeastOneSelect ?
                            <>
                                {
                                    manualMode ?
                                        null
                                        :
                                        <Divider type='vertical' />
                                }
                                {
                                    selectByDay &&
                                    <CircleButton
                                        small
                                        icon={<FAIcon prefix='fad' name='calendar-day' />}
                                        title={intl.formatMessage({ defaultMessage: "View by day" })}
                                        disabled={disabled}
                                        type={mode === "date" ? 'primary' : 'default'}
                                        onClick={() => this.onChangePickerType(undefined)} />

                                }
                                {
                                    selectByWeek &&
                                    <CircleButton
                                        small
                                        icon={<FAIcon prefix='fad' name='calendar-week' />}
                                        title={intl.formatMessage({ defaultMessage: "View by week" })}
                                        disabled={disabled}
                                        type={mode === PickerMode.WEEK ? 'primary' : 'default'}
                                        onClick={() => this.onChangePickerType(PickerMode.WEEK)} />
                                }
                                {
                                    selectByMonth &&
                                    <CircleButton
                                        small
                                        icon={<FAIcon prefix='fad' name='calendar-days' />}
                                        title={intl.formatMessage({ defaultMessage: "View by month" })}
                                        disabled={disabled}
                                        type={mode === PickerMode.MONTH ? 'primary' : 'default'}
                                        onClick={() => this.onChangePickerType(PickerMode.MONTH)} />
                                }
                                {
                                    selectByYear &&
                                    <CircleButton
                                        small
                                        icon={<FAIcon prefix='fad' name='calendar' />}
                                        title={intl.formatMessage({ defaultMessage: "View by year" })}
                                        disabled={disabled}
                                        type={mode === PickerMode.YEAR ? 'primary' : 'default'}
                                        onClick={() => this.onChangePickerType(PickerMode.YEAR)} />
                                }
                            </>
                            : null
                    }
                    {
                        selectByManual ?
                            <>
                                <Divider type='vertical' />
                                <CircleButton
                                    small
                                    icon={<FAIcon prefix='fad' name='calendar-pen' />}
                                    title={intl.formatMessage({ defaultMessage: "Custom range" })}
                                    disabled={disabled}
                                    type={manualMode ? 'primary' : 'default'}
                                    onClick={() => !manualMode && this.setState({ manualMode: true })} />
                            </>
                            : null
                    }
                </SpaceContent>
            </SpaceContent>
        )

        return (
            manualMode ?
                <div className='rangepicker-with-actions-container'>
                    <div className='rangepicker-container'>
                        <DatePicker.RangePicker
                            allowClear={false}
                            className='rangepicker-container-picker'
                            popupClassName="rangepicker-container-popup"
                            value={[valueFrom, valueTo ?? valueFrom.clone()]}
                            onChange={(dates) => !disabled && this.onChangeDates(dates)}
                            superPrevIcon={<FAIcon size='sm' prefix='fad' name='chevrons-left' />}
                            superNextIcon={<FAIcon size='sm' prefix='fad' name='chevrons-right' />}
                            prevIcon={<FAIcon size='sm' prefix='fad' name='chevron-left' />}
                            nextIcon={<FAIcon size='sm' prefix='fad' name='chevron-right' />}
                            renderExtraFooter={footer}
                        />
                    </div>
                </div>
                :
                <div className='datepicker-with-actions-container'>
                    <CircleButton
                        small
                        disabled={disabled}
                        icon={<FAIcon prefix='fal' name='chevron-left' />}
                        onClick={() => this.previousDate()} />
                    <div className='datepicker-container'>
                        <DatePicker
                            className='datepicker-container-picker'
                            popupClassName="datepicker-container-popup"
                            placement="bottomLeft"
                            suffixIcon={null}
                            inputReadOnly
                            bordered={false}
                            format={(date) => this.weekFormat(date, pickerType)}
                            showToday={false}
                            disabledDate={(e) => disableFuture ? this.disableFutureDates(e) : false}
                            allowClear={false}
                            picker={pickerType}
                            value={valueFrom}
                            onChange={(date) => !disabled && this.onChangeDate(date)}
                            panelRender={(panel) => (
                                <div style={{ position: "relative" }}>
                                    <Spin spinning={loadingData} indicator={<FAIcon prefix='fas' name='spinner-third' spin />}>
                                        {panel}
                                    </Spin>
                                </div>
                            )}
                            superPrevIcon={<FAIcon size='sm' prefix='fad' name='chevrons-left' />}
                            superNextIcon={<FAIcon size='sm' prefix='fad' name='chevrons-right' />}
                            prevIcon={<FAIcon size='sm' prefix='fad' name='chevron-left' />}
                            nextIcon={<FAIcon size='sm' prefix='fad' name='chevron-right' />}
                            renderExtraFooter={footer}
                        />
                    </div>
                    <CircleButton
                        small
                        disabled={disabled || (disableFuture && (valueTo ?? valueFrom)?.clone().add(1, "day").isAfter(moment(), 'day'))}
                        icon={<FAIcon prefix='fal' name='chevron-right' />}
                        onClick={() => this.nextDate()} />
                </div>
        );
    }
}

export default injectIntl(AmazingDatePicker);