import { Col, Row } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { usersColumns } from '../../../utils/tableUtils';
import { StaffType, User, UserSmall } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { IntlProps } from '../../app/LanguageProvider';
import TableTransfer from '../../common/fields/tableTransfer';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends IntlProps, ReduxProps {

    staffTypeShow: StaffType;
    users: User[];
    setStaffTypeEdit: (staffType: StaffType) => void;
}

interface State {
}


class StaffTypeShowForm extends React.Component<Props, State> {


    onChange = (nextTargetKeys: number[]) => {
        const staffType = { ...this.props.staffTypeShow };
        const users = this.props.users.filter(u => nextTargetKeys.findIndex(id => id === u.id) !== -1);
        const parsedUsers: UserSmall[] = [];
        users.forEach((u) => {
            const user: UserSmall = {
                id: u.id,
                firstName: u.first_name,
                lastName: u.last_name,
                email: u.email,
            };
            parsedUsers.push(user);
        })
        staffType && (staffType.users = parsedUsers);
        this.props.setStaffTypeEdit(staffType);
    };

    render() {
        const { staffTypeShow, users, intl, isSmartphone } = this.props;
        return (
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }}>
                    <TableTransfer
                        className={"__group-member-transfert"}
                        titles={[
                            <span key={`TableTransfer-users-avalable`} style={{ textTransform: "uppercase", fontSize: "93%" }}><FormattedMessage defaultMessage={'Available users'} /></span>,
                            <span key={`TableTransfer-users-members-of-group`} style={{ textTransform: "uppercase", fontWeight: 'bold', fontSize: "95%" }}><FormattedMessage defaultMessage={'Group members'} /></span>
                        ]}
                        rowKey={(item: any) => item.id}
                        dataSource={users}
                        columns={usersColumns(intl, isSmartphone).filter(c => c.key !== "contact.mobile" && c.key !== 'active' && c.key !== "username" && c.key !== "role" && (this.props.company && ['read-only', 'read-write'].includes(this.props.company.userCodeDisplayAuthorization) || c.key !== 'code'))}
                        targetKeys={staffTypeShow.users ? staffTypeShow.users.map(user => user.id) : []}
                        onChange={this.onChange}
                        filterOption={(inputValue, item) => `${item.last_name}${item.first_name}`.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
                    />
                </Col>
            </Row>
        )
    }
}



const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    company: state.user.company
});
const connector = connect(mapStateToProps);
export default connector(injectIntl(StaffTypeShowForm));