import { Spin, Tag } from 'antd';
import { ColumnGroupType, ColumnsType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeUsers } from '../../../../store/actions/teamManagement';
import { selectActiveReportUsers } from '../../../../store/selectors/usersSelectors';
import Network from '../../../../utils/network';
import { IFilterUsers, RouterProps, User, UserHoursSummary, UserHoursSummaryWithContractDetails } from '../../../../utils/types/generalTypes';
import { NetworkUserHoursSummaries } from '../../../../utils/types/networkTypes';
import { ApplicationState, StoreDispatch } from '../../../../utils/types/storeTypes';
import { filterUsers, isNullOrEmpty, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import AmazingDatePicker, { PickerMode } from '../../../common/fields/AmazingDatePicker/amazingDatePicker';
import CircleButton from '../../../common/fields/circleButton';
import Anticon from '../../../common/general/anticon';
import EmptyData from '../../../common/general/emptyData';
import VirtualTable from '../../../common/general/virtualTable';
import { FilterSidebar } from '../../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';
import Filters from '../../../planningPerf/tabs/common/filters';


type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, ContainerTabsItemProps, RouterProps, IntlProps {
}


interface State {
    year: number;
    isLoading: boolean;
    hoursSummary: NetworkUserHoursSummaries;
    userContractDetails: {
        userId: number;
        summariesByContracts: UserHoursSummaryWithContractDetails[];
    }[];
    expandedUserIds: number[];
    loadingUserIds: number[];
    columns: ColumnsType<UserHoursSummary>;
    filters: IFilterUsers;
}

class HoursTab extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            year: moment().year(),
            isLoading: false,
            hoursSummary: [],
            userContractDetails: [],
            expandedUserIds: [],
            loadingUserIds: [],
            columns: this.columnsUserHoursSummary(),
            filters: {
                users: [],
                groups: [],
                usersToExclude: []
            },
        };
    }

    componentDidMount() {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        this.loadHoursSummary();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (
            prevState.year !== this.state.year ||
            prevState.isLoading !== this.state.isLoading
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }

        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }
    }

    getExtra = () => {
        const { intl } = this.props;
        const { isLoading, year } = this.state;
        return (
            <>
                <AmazingDatePicker
                    initialPickerType={PickerMode.YEAR}
                    loadingData={isLoading}
                    controlled={{
                        valueFrom: moment(year, "YYYY").startOf("year"),
                        onChange: this.onChangeDate,
                    }}
                />
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={() => this.loadHoursSummary()}
                    loading={isLoading} />
            </>
        );
    }

    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <Filters
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setState(prevState => ({ filters: { ...prevState.filters, users: val } }))
                }}
                groups={{
                    selectedGroups: filters.groups,
                    usersToExclude: filters.usersToExclude,
                    changeGroups: (groups, usersToExclude) => this.setState(prevState => ({ filters: { ...prevState.filters, groups, usersToExclude } }))
                }}
            />
        )
        return [FilterSidebar(content, intl)];
    }

    resetFilters = () => this.setState({ filters: { users: [], groups: [], usersToExclude: [] } });

    onChangeDate = (value: Moment | null) => {
        this.setState({ year: value ? value.year() : moment().year() }, () => this.loadHoursSummary())
    }

    loadHoursSummary = () => {
        this.setState({
            isLoading: true,
            expandedUserIds: [],
            loadingUserIds: []
        });
        Network.usersHoursSummary(this.state.year).then(
            (response) => {
                this.setState({ hoursSummary: response, isLoading: false });
            }
        );
    };

    // TODO: Replace by new ones in utils.tsx
    decimalize(numberToDecimalize: number, nbDecimals = 2, round = true) {
        if (round)
            return (Math.round(numberToDecimalize * 100) / 100).toFixed(nbDecimals);
        else
            return numberToDecimalize.toFixed(nbDecimals);
    }

    loadContractChangeDetails = (userId: number) => {
        if (this.state.hoursSummary.find(hs => hs.contract && hs.user.id === userId)) {
            this.setState({
                hoursSummary: this.state.hoursSummary.filter(hs => !hs.contract || (hs.contract && hs.user.id !== userId)),
                expandedUserIds: this.state.expandedUserIds.filter(u => u != userId)
            });

        } else {
            this.setState({ loadingUserIds: [...this.state.loadingUserIds, userId] });
            Network.userHoursSummaryWithContractDetails(userId, this.state.year)
                .then((response) => {
                    if (response.length > 0) {
                        const userIndex = this.state.hoursSummary.findIndex(s => s.user.id === userId);
                        const newHoursSummaries = cloneDeep(this.state.hoursSummary);
                        for (let i = 0; i < response.length; i++) {
                            const r = response[i];
                            newHoursSummaries.splice(userIndex + 1 + i, 0, { ...r.report, contract: r.contract });
                        }
                        this.setState({
                            hoursSummary: newHoursSummaries,
                            expandedUserIds: [...this.state.expandedUserIds, userId],
                            loadingUserIds: this.state.loadingUserIds.filter(u => u !== userId)
                        });
                    } else {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the contract' }), "error");
                    }
                });
        }
    };

    createMonthColumn = (month: number): ColumnGroupType<UserHoursSummary> => {
        const monthMoment = moment().month(month - 1);
        const key = monthMoment.format('MMM');

        const oddOrEven = month % 2 == 1 ? 'odd' : 'even';

        const headerText = monthMoment.format('MMMM');
        const header = <div style={{ flexDirection: 'column', display: 'flex' }}>
            {headerText[0].toUpperCase()}{headerText.substring(1, headerText.length).toLowerCase()}
        </div>;

        return {
            title: header,
            key: `${key}Hours`,
            className: `__report-hours-summary-fixed-${oddOrEven}-main`,
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: `${key}Hours_todoHours`,
                    className: `__report-hours-summary-fixed-${oddOrEven}`,
                    render: (value, record) => {
                        const monthTotal = record.monthTotals.find(total => total.month === month);
                        if (monthTotal === undefined) {
                            return <span></span>;
                        } else {
                            return (
                                <span>{this.decimalize(monthTotal.todo, 2, true)}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: `${key}Hours_totalCalculatedBalance`,
                    className: `__report-hours-summary-fixed-${oddOrEven}`,
                    render: (value, record) => {
                        const monthTotal = record.monthTotals.find(total => total.month === month);
                        if (monthTotal === undefined) {
                            return <span></span>;
                        } else {
                            return (
                                <span>{this.decimalize(monthTotal.effectiveHours, 2, true)}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: `${key}Hours_nextBalance`,
                    className: `__report-hours-summary-fixed-${oddOrEven}`,
                    render: (value, record) => {
                        const monthTotal = record.monthTotals.find(total => total.month === month);
                        if (monthTotal === undefined) {
                            return <span></span>;
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{this.decimalize(monthTotal.toReport, 2, true)}</Tag>
                            );
                        }
                    }
                }
            ]
        };
    };

    renderUserColumn = (record: UserHoursSummary) => {
        if (record.contract) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className='__contract-row'>
                    <p style={{ fontStyle: 'italic' }}>{record.contract.name}</p>
                </div>
            );
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>{record.user.fullName}</div>
            </div>
        );
    };

    getMultipleContractsIcon = (count: number) => {
        if (count > MULTIPLE_CONTRACTS_ICONS.length)
            return <FAIcon prefix='fad' name='square' />;
        else
            return MULTIPLE_CONTRACTS_ICONS[count - 1];
    };

    renderWorkRateColumn = (record: UserHoursSummary) => {
        if (record.contract) {
            return <span>{record.contract.workRate}%</span>;
        } else if (record.contractChanges.length === 0) {
            return <span>{record.workRate}%</span>;
        } else {
            const contractChanges = record.contractChanges.length;
            let status = 'closed';
            if (this.state.loadingUserIds.includes(record.user.id)) {
                status = 'loading';
            }
            else if (this.state.expandedUserIds.includes(record.user.id))
                status = 'opened';

            return (
                <div>
                    {
                        record.contractChanges.map(contractChange => (
                            <div key={`${record.user.id}_${contractChange.date}`}>
                                {/* <Badge className='__badge-spinner' count={status === 'opened' ? <div style={{ background: 'white', borderRadius: '100%' }}><AiOutlineCloseCircle /></div> : 0} offset={['-5px', '5px']}> */}
                                <CircleButton
                                    small
                                    withoutTooltip
                                    icon={<Anticon>{status === 'loading' ? <Spin /> : this.getMultipleContractsIcon(record.contractChanges.length)}</Anticon>}
                                    onClick={() => this.loadContractChangeDetails(record.user.id)}
                                    title={this.props.intl.formatMessage({ defaultMessage: '{count, plural, one {There is {count} contract change during the year} other {There is {count} contract changes during the year}}' }, { count: contractChanges })}
                                />
                                {/* </Badge> */}
                            </div>
                        ))
                    }

                </div>
            );
        }
    };

    renderInitialHoursColumn = (record: UserHoursSummary) => {
        if (record.contract) {
            return <Tag className='__report-groups-users-tags'>{record.monthTotals.length > 0 ? record.monthTotals[0].initialHours : 0}</Tag>;
        } else {
            return <Tag className='__report-groups-users-tags'>{record.monthTotals.length > 0 ? record.monthTotals[0].initialHours : 0}</Tag>;
        }
    };

    columnsUserHoursSummary = (): ColumnsType<UserHoursSummary> => [
        {
            title: <FormattedMessage defaultMessage={'Employee'} />,
            key: 'user',
            fixed: 'left',
            className: `__report-user __width_180 __no-padding`,
            render: (value, record) => this.renderUserColumn(record),
        },
        {
            title: <FormattedMessage defaultMessage={'Rate'} />,
            align: 'center',
            key: 'workRate',
            fixed: 'left',
            className: `report-user-background __width_80`,
            render: (_, record) => this.renderWorkRateColumn(record),
            onCell: (record) => {
                if (record.contractChanges.length > 0)
                    return {
                        colSpan: 2,
                        className: "__table-text-centered"
                    };

                return {
                    className: "__table-text-right"
                };
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Initial'} />,
            key: 'inithours',
            align: 'center',
            fixed: 'left',
            className: `report-user-background __width_90 __table-text-right`,
            render: (_, record) => this.renderInitialHoursColumn(record),
            onCell: (record) => {
                if (record.contractChanges.length > 0)
                    return {
                        colSpan: 0
                    };

                return {};
            }
        },
        this.createMonthColumn(1),
        this.createMonthColumn(2),
        this.createMonthColumn(3),
        this.createMonthColumn(4),
        this.createMonthColumn(5),
        this.createMonthColumn(6),
        this.createMonthColumn(7),
        this.createMonthColumn(8),
        this.createMonthColumn(9),
        this.createMonthColumn(10),
        this.createMonthColumn(11),
        this.createMonthColumn(12),
        {
            title: <FormattedMessage defaultMessage={'To report'} />,
            key: 'to_report',
            fixed: 'right',
            className: '__report-hours-summary-to-report',
            render: (_, record) => {
                if (record.monthTotals === undefined) {
                    return <span></span>;
                } else {
                    return (
                        <Tag className={`__report-groups-users-tags${!record.contract ? '-important' : ''}`}>{record.monthTotals.length > 0 ? this.decimalize(record.monthTotals[record.monthTotals.length - 1].toReport, 2, false) : 0.0}</Tag>
                    );
                }
            }
        }
    ];

    render() {
        const { groups, users } = this.props;
        const { hoursSummary, isLoading, filters } = this.state;

        let tableHeight = this.props.height - 172;
        if (tableHeight < 250) tableHeight = 250;

        const filteredUsers = filterUsers(users, groups, filters);
        const filteredHoursSummary = hoursSummary.filter(d => filteredUsers?.some(u => u.id === d.user.id));

        return (
            <Spin spinning={isLoading} size="large" indicator={< FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                {
                    isNullOrEmpty(filteredUsers) ?
                        <EmptyData />
                        :
                        <VirtualTable
                            className="__basic-table"
                            dataSource={filteredHoursSummary}
                            columns={this.state.columns}
                            pagination={false}
                            rowKey={(s: UserHoursSummary) => `report-hours-summary-${s.user.id}-c${s.contract?.id ?? 'none'}`}
                            scroll={{ x: true, y: tableHeight }}
                            rowClassName={(record: UserHoursSummary) => record.contract ? '__user_report-child' : ''}
                        />

                }
            </Spin>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
});

const mapStateToProps = (state: ApplicationState) => ({
    users: selectActiveReportUsers(state),
    groups: state.teamManagement.groups,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withRouter(injectIntl(HoursTab)));


// #region Constants
const MULTIPLE_CONTRACTS_ICONS = [
    <FAIcon prefix='fad' name='square-1' key="multipleContracsIcon1" />,
    <FAIcon prefix='fad' name='square-2' key="multipleContracsIcon2" />,
    <FAIcon prefix='fad' name='square-3' key="multipleContracsIcon3" />,
    <FAIcon prefix='fad' name='square-4' key="multipleContracsIcon4" />,
    <FAIcon prefix='fad' name='square-5' key="multipleContracsIcon5" />,
    <FAIcon prefix='fad' name='square-6' key="multipleContracsIcon6" />,
    <FAIcon prefix='fad' name='square-7' key="multipleContracsIcon7" />,
    <FAIcon prefix='fad' name='square-8' key="multipleContracsIcon8" />,
    <FAIcon prefix='fad' name='square-9' key="multipleContracsIcon9" />
];
// #endregion