import { Avatar, Button, Card, Col, List, Modal, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { cloneDeep } from 'lodash';
import { matchSorter } from 'match-sorter';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { enabledIntegrationsFetched } from '../../../store/features/integrationsSlice';
import { GREEN_COLOR, RED_COLOR } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { DictionaryString, IGlobalOfficeUsers, IHotelaUsers, IIntegrationsType, IIntegrationsUsersBase, IIntegrationUsers, IUserAvailable } from '../../../utils/types/generalTypes';
import { NetworkResponse } from '../../../utils/types/networkTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { normalizeString, showNotification } from '../../../utils/utils';
import FAIcon from '../../common/FAIcon';
import CircleButton from '../../common/fields/circleButton';
import VirtualTable from '../../common/general/virtualTable';
import { tableColumnTextFilterConfig } from '../../courseManagement/tableSearch';
import { UsersManagementRef } from './export';

interface Props {
    selectedIntegration: IIntegrationsType | undefined;
    isModal?: boolean;
}

interface InvalidEmails {
    email: string,
    emailIsValid: boolean,
    emailIsValidError: string;
}
interface InvalidUsernames {
    username: string,
    usernameIsValid: boolean,
    usernameIsValidError: string;
}

interface IntegrationContract {
    extactMatch: number[];
    overlapping: number[];
    periodsData: {
        startDate: string;
        endDate?: string;
        rate: number;
    };
}

interface IntegrationContractSelect {
    value?: number;
    label?: string;
    isExact?: boolean;
}

interface IntegrationContractWithSunkhroContract {
    sunkhroContract: number | undefined;
    integrationContract: number;
}

const UsersManagement = (props: Props, ref: React.ForwardedRef<UsersManagementRef>) => {
    const [loading, setLoading] = useState<boolean>(false);

    const [users, setUsers] = useState<IIntegrationUsers[]>([]);
    const [selectedUser, setSelectedUser] = useState<IIntegrationUsers | undefined>(undefined);
    const [allUsers, setAllUsers] = useState<boolean>(true);
    const [usersAvailable, setUsersAvailable] = useState<IUserAvailable[]>([]);

    const [integrationContracts, setIntegrationContracts] = useState<DictionaryString<IntegrationContract>>({});
    const [userIdToLink, setUserIdToLink] = useState<number | undefined>(undefined);
    const [integrationContractsToChange, setIntegrationContractsToChange] = useState<IntegrationContractWithSunkhroContract[]>([]);

    const reduxUsers = useSelector((state: ApplicationState) => state.teamManagement.users);
    const dispatch = useDispatch();
    const intl = useIntl();

    const height = useSelector((state: ApplicationState) => state.window.height);

    useImperativeHandle(ref, () => ({
        refresh: () => {
            refreshIntegration();
        }
    }));

    const columns: ColumnProps<IIntegrationUsers>[] = [
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            dataIndex: 'firstName',
            key: 'firstName',
            className: '__min-width-200',
            onFilter: (value, record) => {
                return record.firstName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<IIntegrationUsers>(),
            sorter: (a: IIntegrationUsers, b: IIntegrationUsers) => !a.firstName ? -1 : !b.firstName ? 1 : a.firstName < b.firstName ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            dataIndex: 'lastName',
            key: 'lastName',
            className: '__min-width-200',
            onFilter: (value, record) => {
                return record.lastName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<IIntegrationUsers>(),
            sorter: (a: IIntegrationUsers, b: IIntegrationUsers) => !a.lastName ? -1 : !b.lastName ? 1 : a.lastName < b.lastName ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Linked'} />,
            key: 'userId',
            className: '__width_80',
            defaultSortOrder: 'descend',
            fixed: 'right',
            sorter: (a: IIntegrationUsers, b: IIntegrationUsers) => a.userId && !b.userId ? -1 : !a.userId && b.userId ? 1 : 0,
            render: (integrationUser: IIntegrationUsers) => <div style={{ width: '100%', textAlign: 'center' }}>{integrationUser.userId ? <FAIcon prefix='fad' name='circle-check' color={GREEN_COLOR} /> : <FAIcon prefix='fad' name='circle-xmark' color={RED_COLOR} />}</div>
        },
    ];


    const availableColumns: ColumnProps<IUserAvailable>[] = [
        {
            key: 'image',
            title: <FormattedMessage defaultMessage={'Image'} />,
            dataIndex: 'image',
            className: '__width_80',
            align: 'center',
            render: (_, record) => <><Avatar icon={<FAIcon prefix='fad' name='user' />} src={record.image} style={{ background: 'var(--primary-color)' }} /></>
        },
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            dataIndex: 'firstName',
            key: 'firstName',
            className: '__min-width-200',
            onFilter: (value, record) => {
                return record.firstName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<IUserAvailable>(),
            sorter: (a: IUserAvailable, b: IUserAvailable) => !a.firstName ? -1 : !b.firstName ? 1 : a.firstName < b.firstName ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            dataIndex: 'lastName',
            key: 'lastName',
            className: '__min-width-200',
            onFilter: (value, record) => {
                return record.lastName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<IUserAvailable>(),
            sorter: (a: IUserAvailable, b: IUserAvailable) => !a.lastName ? -1 : !b.lastName ? 1 : a.lastName < b.lastName ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            dataIndex: 'actions',
            key: 'actions',
            className: '__width_80',
            fixed: 'right',
            render: (_, record) => (
                <Popconfirm
                    title={
                        <>
                            <p style={{ marginBottom: 10 }}><strong><FormattedMessage defaultMessage={'Linking user'} /></strong></p>
                            <p style={{ marginBottom: 20 }}><FormattedMessage defaultMessage={"Linking the user could change the user's contracts on Sunkhronos. Please check the contracts after proceeding."} /></p>
                            <p><FormattedMessage defaultMessage={"Do you want to continue?"} /></p>
                        </>
                    }
                    onConfirm={() => selectedUser ? linkUser(selectedUser.id, record.id) : showNotification(intl.formatMessage({ defaultMessage: 'Please select an user' }), 'error')}
                    okText={<FormattedMessage defaultMessage={'Link user'} />}
                    disabled={selectedUser?.id === undefined}
                    icon={<FAIcon name='triangle-exclamation' prefix='fad' color='red' />}
                    placement='left'
                >
                    <CircleButton
                        key={`user-link-button-${record.id}`}
                        style={{ minWidth: "32px", backgroundColor: 'var(--background-color)', color: 'var(--font-color)', borderColor: 'var(--border-color-aa)' }}
                        icon={<FAIcon prefix={'fad'} name={'link'} />}
                        title={intl.formatMessage({ defaultMessage: 'Link to user' })}
                        disabled={selectedUser?.id === undefined}
                        small
                    />
                </Popconfirm>
            )
        }
    ];

    const filterBySearchValue = useCallback((users: IUserAvailable[]) => {
        return users;
    }, []);

    const filterIntegratedUser = useCallback((users: IIntegrationUsers[]) => {
        return users.filter(u => (allUsers || (!allUsers && u.userId == null)));
    }, [allUsers]);

    const validateMulitpleEmailAndUsername = useCallback((emailsList: string[], usernamesList: string[]) => {

        Network.validateUsernamesAndEmails(emailsList, usernamesList).then(
            (response: NetworkResponse<{ emails: { email: string, emailIsValid: boolean, emailIsValidError: string; }[], usernames: { username: string, usernameIsValid: boolean, usernameIsValidError: string; }[]; }>) => {
                if (response.error !== undefined && response.error === true) {
                    console.log('ERROR', response.message);
                    showNotification(intl.formatMessage({ defaultMessage: 'Undefined error' }), 'warning');
                } else {
                    const invalidEmails: InvalidEmails[] = [];
                    const validEmails: string[] = [];
                    response.data.emails.forEach((email) => {
                        const emailIsValid = email.emailIsValid;
                        const emailErrorMsg = email.emailIsValidError;

                        if (emailIsValid) {
                            validEmails.push(email.email);
                        } else {
                            invalidEmails.push({ email: email.email, emailIsValid: false, emailIsValidError: emailErrorMsg });
                        }
                    });

                    const invalidUsernames: InvalidUsernames[] = [];
                    const validUsernames: string[] = [];
                    response.data.usernames.forEach((username) => {
                        const usernameIsValid = username.usernameIsValid;
                        const usernameErrorMsg = username.usernameIsValidError;

                        if (usernameIsValid) {
                            validUsernames.push(username.username);
                        } else {
                            invalidUsernames.push({ username: username.username, usernameIsValid: false, usernameIsValidError: usernameErrorMsg });
                        }
                    });
                }
            },
            (error) => {
                console.log('ERROR', error);
                showNotification(intl.formatMessage({ defaultMessage: 'Undefined error' }), 'warning');
            }
        );
    }, [intl]);

    const changeSelectedUser = useCallback((u: IIntegrationUsers | undefined) => {
        setSelectedUser(u);
        if (u !== undefined) {
            const username = `${normalizeString(`${u.firstName}.${u.lastName}`)}`.replaceAll(' ', '.');
            const email = u.email || `${username}@sunkhro.com`;
            const toCreateUser = { ...u, image: '', username, email };
            validateMulitpleEmailAndUsername([toCreateUser.email], [toCreateUser.username]);
        }
    }, [validateMulitpleEmailAndUsername]);

    const loadIntegrations = useCallback(async () => await dispatch(enabledIntegrationsFetched({})), [dispatch]);

    // Hotela integration

    const refreshHotelaUsers = useCallback((force = false) => {
        setLoading(true);
        Network.getHotelaUsers().then(
            (response: NetworkResponse<IIntegrationsUsersBase<IHotelaUsers>>) => {
                if (response.error === false) {
                    setUsers(response.data.integrationUsers.map(u => ({ ...u, code: u.clientNumber })));
                    setUsersAvailable(response.data.usersAvailable);
                    if (force) {
                        loadIntegrations();
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading hotela users' }), "error", response.message);
                }
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the hotela users' }), "error");
            }
        );
    }, [intl, loadIntegrations]);

    const linkHotelaUser = useCallback((hoUserId: number, userId: number, contracts?: IntegrationContractWithSunkhroContract[]) => {
        setLoading(true);
        const tmpContracts = contracts && contracts.length > 0 ? contracts.map(c => ({ jobId: c.sunkhroContract || -1, hoPeriodId: c.integrationContract })) : undefined;
        Network.linkHotelaUser(hoUserId, userId, tmpContracts).then(
            (response: NetworkResponse<IIntegrationsUsersBase<IHotelaUsers>>) => {
                if (response.error === false) {
                    setUsers(response.data.integrationUsers.map(u => ({ ...u, code: u.clientNumber })));
                    setUsersAvailable(response.data.usersAvailable);
                    setSelectedUser(undefined);
                    loadIntegrations();
                    setIntegrationContracts({});
                    setIntegrationContractsToChange([]);
                    showNotification(intl.formatMessage({ defaultMessage: 'User linked successfully' }), "success");
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading hotela users' }), "error", response.message);
                }
                setLoading(false);
            },
            (error) => {
                const err = JSON.parse(error.message);
                if (err.code === 'integrations-multiple-jobs-available-605') {
                    const integrationContractsToChangeNew = Object.keys(err.data).map((dataKey) => {
                        return { integrationContract: parseInt(dataKey), sunkhroContract: undefined }
                    });
                    setIntegrationContractsToChange(integrationContractsToChangeNew);
                    setIntegrationContracts(i => {
                        const contracts = cloneDeep(i);
                        Object.keys(err.data).forEach((dataKey) => {
                            const tmpData = err.data[dataKey];
                            contracts[dataKey] = {
                                extactMatch: tmpData.exactMatch,
                                overlapping: tmpData.overlapping,
                                periodsData: tmpData.periodsData
                            };
                        });
                        return contracts;
                    });
                }
                else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the hotela users' }), "error");
                }
                setLoading(false);
            }
        );
    }, [intl, loadIntegrations]);

    const unLinkHotelaUser = useCallback((hoUserId: number, userId: number) => {
        setLoading(true);
        Network.unLinkHotelaUser(hoUserId, userId).then(
            (response: NetworkResponse<IIntegrationsUsersBase<IHotelaUsers>>) => {
                if (response.error === false) {
                    setUsers(response.data.integrationUsers.map(u => ({ ...u, code: u.clientNumber })));
                    setUsersAvailable(response.data.usersAvailable);
                    setSelectedUser(undefined);
                    loadIntegrations();
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading hotela users' }), "error", response.message);
                }
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the hotela users' }), "error");
            }
        );
    }, [intl, loadIntegrations]);

    // GO integration
    const refreshGoUsers = useCallback((force = false) => {
        setLoading(true);
        Network.getGoUsers().then(
            (response: NetworkResponse<IIntegrationsUsersBase<IGlobalOfficeUsers>>) => {
                if (response.error === false) {
                    setUsers(response.data.integrationUsers.map(u => ({ ...u, code: u.clientNumber })));
                    setUsersAvailable(response.data.usersAvailable);
                    if (force) {
                        loadIntegrations();
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading global-office users' }), "error", response.message);
                }
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the global-office users' }), "error");
            }
        );
    }, [intl, loadIntegrations]);
    const linkGoUser = useCallback((goUserId: number, userId: number, contracts?: IntegrationContractWithSunkhroContract[]) => {
        setLoading(true);
        const tmpJobId = contracts && contracts.length > 0 ? contracts[0].sunkhroContract : undefined;
        Network.linkGoUser(goUserId, userId, tmpJobId).then(
            (response: NetworkResponse<IIntegrationsUsersBase<IGlobalOfficeUsers>>) => {
                if (response.error === false) {
                    setUsers(response.data.integrationUsers.map(u => ({ ...u, code: u.clientNumber })));
                    setUsersAvailable(response.data.usersAvailable);
                    setSelectedUser(undefined);
                    loadIntegrations();
                    setIntegrationContracts({});
                    setIntegrationContractsToChange([]);
                    showNotification(intl.formatMessage({ defaultMessage: 'User linked successfully' }), "success");
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading global-office users' }), "error", response.message);
                }
                setLoading(false);
            },
            (error) => {
                const err = JSON.parse(error.message);
                if (err.code === 'integrations-multiple-jobs-available-605') {
                    setIntegrationContracts(i => {
                        const contracts = cloneDeep(i);
                        Object.keys(err.data).forEach((dataKey) => {
                            const tmpData = err.data[dataKey];
                            contracts[dataKey] = {
                                extactMatch: tmpData.exactMatch,
                                overlapping: tmpData.overlapping,
                                periodsData: tmpData.periodsData
                            };
                            setIntegrationContractsToChange([...integrationContractsToChange, { integrationContract: parseInt(dataKey), sunkhroContract: undefined }]);
                        });
                        return contracts;
                    });
                }
                else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the hotela users' }), "error");
                }
                setLoading(false);
            }
        );
    }, [integrationContractsToChange, intl, loadIntegrations]);

    const unLinkGoUser = useCallback((hoUserId: number, userId: number) => {
        setLoading(true);
        Network.unLinkGoUser(hoUserId, userId).then(
            (response: NetworkResponse<IIntegrationsUsersBase<IGlobalOfficeUsers>>) => {
                if (response.error === false) {
                    setUsers(response.data.integrationUsers.map(u => ({ ...u, code: u.clientNumber })));
                    setUsersAvailable(response.data.usersAvailable);
                    setSelectedUser(undefined);
                    loadIntegrations();
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading global-office users' }), "error", response.message);
                }
                setLoading(false);
            },
            () => {
                setLoading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the global-office users' }), "error");
            }
        );
    }, [intl, loadIntegrations]);

    //Function to route to integrator

    const refreshIntegration = useCallback((force = false) => {
        if (props.selectedIntegration === 'hotela') {
            refreshHotelaUsers(force);
        } else if (props.selectedIntegration === 'globaloffice') {
            refreshGoUsers(force);
        }
    }, [props.selectedIntegration, refreshHotelaUsers, refreshGoUsers]);

    const linkUser = useCallback((integrationUserId: number, userId: number, contracts?: any) => {
        setUserIdToLink(userId);
        if (props.selectedIntegration === 'hotela') {
            linkHotelaUser(integrationUserId, userId, contracts);
        } else if (props.selectedIntegration === 'globaloffice') {
            linkGoUser(integrationUserId, userId, contracts);
        }
    }, [props.selectedIntegration, linkHotelaUser, linkGoUser]);

    const unLinkUser = useCallback((integrationUserId: number, userId: number) => {
        if (props.selectedIntegration === 'hotela') {
            unLinkHotelaUser(integrationUserId, userId);
        } else if (props.selectedIntegration === 'globaloffice') {
            unLinkGoUser(integrationUserId, userId);
        }
    }, [props.selectedIntegration, unLinkHotelaUser, unLinkGoUser]);

    //! Only when the tab is mounted, don't put the function in the dependency array for pity
    useEffect(() => {
        refreshIntegration();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const terms = useMemo(() => {
        if (selectedUser) {
            let string = `${normalizeString(`${selectedUser.firstName}.${selectedUser.lastName}`)}`.replaceAll(' ', '.');
            string += ` ${normalizeString(selectedUser.firstName).replaceAll('.', ' ').replaceAll(',', ' ')}`;
            string += ` ${normalizeString(selectedUser.lastName).replaceAll('.', ' ').replaceAll(',', ' ')}`;
            if (selectedUser.email != null && selectedUser.email !== '') {
                string += ` ${normalizeString(selectedUser.lastName).replaceAll('.', ' ').replaceAll(',', ' ')}`;
            }
            return string.split(' ');

        }
        return [''];

    }, [selectedUser]);

    const sortedAvailableUsers = useMemo(() => {
        if (selectedUser) {
            return terms.reduceRight((results, term) => matchSorter(
                results,
                term,
                {
                    threshold: matchSorter.rankings.NO_MATCH,
                    keepDiacritics: true,
                    keys: [
                        item => `${normalizeString(`${item.firstName}.${item.lastName}`)}`.replaceAll(' ', '.'),
                        item => item.firstName,
                        item => item.lastName,
                        item => `${normalizeString(`${item.username}`)}`,
                        item => `${normalizeString(`${item.email}`)}`
                    ]
                }
            ), filterBySearchValue(usersAvailable));
        }
        return filterBySearchValue(usersAvailable);
    }, [terms, selectedUser, usersAvailable, filterBySearchValue]);

    const selectedUserToLinkFromRedux = useMemo(() => {
        return reduxUsers.find(ru => ru.id === userIdToLink);
    }, [reduxUsers, userIdToLink]);

    const renderContractOptions = useCallback((data: IntegrationContract, alreadySelectedContractId: number[]): IntegrationContractSelect[] => {
        const contracts: IntegrationContractSelect[] = [];

        // Exact contracts
        selectedUserToLinkFromRedux?.job?.filter(j => j.id && data.extactMatch.includes(j.id) && !alreadySelectedContractId.includes(j.id))?.forEach((c) => {
            contracts.push({ label: c.name, value: c.id, isExact: false });
        });

        // Overlapping contracts
        selectedUserToLinkFromRedux?.job?.filter(j => j.id && data.overlapping.includes(j.id) && !alreadySelectedContractId.includes(j.id))?.forEach((c) => {
            contracts.push({ label: c.name, value: c.id, isExact: true });
        });
        contracts.push({label: intl.formatMessage({defaultMessage: 'New Contract'}), value:-1, isExact: false})

        return contracts;
    }, [selectedUserToLinkFromRedux?.job, intl]);

    const onContractMatchConfirm = useCallback(() => {
        const contracts = cloneDeep(integrationContractsToChange);

        if (contracts.some(e => e.sunkhroContract === undefined)) {
            showNotification(intl.formatMessage({ defaultMessage: 'Please select contracts to override' }), 'error');
            return;
        }

        if (selectedUserToLinkFromRedux && selectedUser)
            linkUser(selectedUser.id, selectedUserToLinkFromRedux?.id, contracts);

    }, [integrationContractsToChange, intl, linkUser, selectedUser, selectedUserToLinkFromRedux]);

    const tableHeight = useMemo(() => height - 349 < 250 ? 250 : height - 300, [height]);

    return (
        <>
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }} xl={{ span: 11 }}>
                    <Card
                        size='small'
                        title={<FormattedMessage defaultMessage={'Link user'} />}
                        extra={[
                            <Space key='integration-links'>
                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Display all users' })}>
                                    <CircleButton
                                        icon={<FAIcon prefix='fad' name='user-magnifying-glass' />}
                                        type={allUsers ? 'primary' : 'default'}
                                        onClick={() => setAllUsers(!allUsers)}
                                        size='small'
                                    />
                                </Tooltip>
                            </Space>
                        ]}
                    >
                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }}>
                                <VirtualTable
                                    style={{ marginTop: 15, flex: 1, overflow: 'auto' }}
                                    dataSource={filterIntegratedUser(users)}
                                    rowKey={(e: IIntegrationUsers) => e.id}
                                    onRow={(e: any) => ({
                                        onClick: () => {
                                            if (selectedUser && selectedUser.id === e.id) {
                                                changeSelectedUser(undefined);
                                            } else {
                                                changeSelectedUser(e);
                                            }
                                        },
                                    })}
                                    rowSelection={{
                                        hideSelectAll: true,
                                        onSelect: (user: any) => {
                                            if (selectedUser && selectedUser.id === user.id) {
                                                changeSelectedUser(undefined);
                                            } else {
                                                changeSelectedUser(user);
                                            }
                                        },
                                        selectedRowKeys: selectedUser ? [selectedUser.id] : undefined,
                                        type: 'checkbox'
                                    }}
                                    columns={columns}
                                    scroll={{ x: true, y: tableHeight }}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} xl={{ span: 13 }}>
                    <Card
                        size='small'
                        title={selectedUser && selectedUser.userId != null ? <FormattedMessage defaultMessage={'Sunkhronos user'} /> : <FormattedMessage defaultMessage={'Available sunkhronos users'} />}
                    >
                        {
                            selectedUser?.userId == null ?
                                <>
                                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <VirtualTable
                                            style={{ marginTop: 15, flex: 1, overflow: 'auto' }}
                                            dataSource={sortedAvailableUsers}
                                            columns={availableColumns}
                                            scroll={{ x: true, y: tableHeight }}
                                            pagination={false}
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <List
                                            itemLayout={'vertical'}
                                            dataSource={reduxUsers.filter(u => u.id === selectedUser.userId)}
                                            renderItem={(user) => {
                                                return (
                                                    <List.Item
                                                        key={`user-list-item-${user.id}`}
                                                        extra={[
                                                            <Popconfirm
                                                                key={`user-unlink-button-${user.id}`}
                                                                title={<FormattedMessage defaultMessage={'Do you want to unlink this user?'} />}
                                                                onConfirm={() => unLinkUser(selectedUser.id, user.id)}
                                                                placement="topRight"
                                                            >
                                                                <CircleButton
                                                                    style={{ minWidth: "32px", backgroundColor: 'var(--background-color)', color: 'var(--font-color)', borderColor: 'var(--border-color-aa)' }}
                                                                    icon={<FAIcon prefix={'fad'} name={'link-slash'} />}
                                                                    title={intl.formatMessage({ defaultMessage: 'Unlink user' })}
                                                                />
                                                            </Popconfirm>
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={<Avatar size="large" src={user.image} icon={<FAIcon prefix='fad' name='user' />} style={{ backgroundColor: 'var(--primary-color)' }} />}
                                                            title={
                                                                <div>
                                                                    <span>{`${user.code ? `(${user.code}) ` : ''}${user.first_name} ${user.last_name}`}</span>
                                                                </div>
                                                            }
                                                        />
                                                        <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <span><FormattedMessage defaultMessage={'Email'} />{':'}</span>
                                                                <span>{user.email}</span>
                                                            </div>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <span><FormattedMessage defaultMessage={'Username'} />{':'}</span>
                                                                <span>{user.username}</span>
                                                            </div>
                                                        </div>
                                                    </List.Item>

                                                );
                                            }
                                            }
                                        >
                                        </List>
                                    </div>
                                </>
                        }
                    </Card>
                </Col>
            </Row>
            <Modal
                open={Object.keys(integrationContracts).length > 0}
                onCancel={() => {
                    setIntegrationContracts({});
                    setIntegrationContractsToChange([]);
                }}
                width={700}
                title={<FormattedMessage defaultMessage={'Contracts override'} />}
                footer={[
                    <Space key='integration-link-contract-footer'>
                        <Button
                            type='default'
                            disabled={loading}
                            onClick={() => {
                                setIntegrationContracts({});
                                setIntegrationContractsToChange([]);
                            }}
                        >
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>
                        <Popconfirm
                            title={
                                <>
                                    <p style={{ marginBottom: 10 }}><strong><FormattedMessage defaultMessage={'Override contracts'} /></strong></p>
                                    <p style={{ marginBottom: 20 }}><FormattedMessage defaultMessage={"This action will override selected contracts with the integrations contracts."} /></p>
                                    <p><FormattedMessage defaultMessage={"Do you want to continue?"} /></p>
                                </>
                            }
                            onConfirm={onContractMatchConfirm}
                            okText={<FormattedMessage defaultMessage={'Confirm and override'} />}
                            disabled={selectedUser?.id === undefined}
                            icon={<FAIcon name='triangle-exclamation' prefix='fad' color='red' />}
                        >
                            <Button
                                type='primary'
                                loading={loading}
                                disabled={integrationContractsToChange.some(i => i.sunkhroContract === undefined)}
                            >
                                <FormattedMessage defaultMessage={'Confirm and override'} />
                            </Button>
                        </Popconfirm>
                    </Space>
                ]}
                closable={!loading}
                maskClosable={!loading}
            >
                <Row gutter={[10, 10]}>
                    {
                        Object.keys(integrationContracts).map(i => {
                            const data = integrationContracts[i];
                            const alreadySelectedContractId: number[] = integrationContractsToChange.filter(c => c.integrationContract !== parseInt(i) && c.sunkhroContract !== undefined).map(c => c.sunkhroContract!)
                            const contracts = renderContractOptions(data, alreadySelectedContractId);
                            const selectedContractId = integrationContractsToChange[integrationContractsToChange.findIndex(c => c.integrationContract === parseInt(i))].sunkhroContract;
                            const selectedContract = selectedUserToLinkFromRedux?.job?.find(j => j.id === selectedContractId);
                            return (
                                <Col xs={{ span: 24 }} key={`contract-${i}`}>
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 12 }}>
                                            <div><FormattedMessage defaultMessage={'Integration contract'} /></div>
                                            <div style={{ fontSize: 14, fontStyle: 'italic', color: 'var(--text-secondary)' }}>
                                                <p>
                                                    {
                                                        data.periodsData.endDate ?
                                                            <FormattedMessage defaultMessage={'From {start} to {end}'} description='Integration contract conflict' values={{ start: moment(data.periodsData.startDate).format(getFormat('DATE')), end: moment(data.periodsData.endDate).format(getFormat('DATE')) }} />
                                                            :
                                                            <FormattedMessage defaultMessage={'From {start}'} description='Integration contract conflict' values={{ start: moment(data.periodsData.startDate).format(getFormat('DATE')) }} />
                                                    }
                                                </p>
                                                <p><FormattedMessage defaultMessage={'Rate of {rate}%'} values={{ rate: data.periodsData.rate }} /></p>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 12 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                            <Row style={{width: '100%'}}>
                                                <Col xs={{ span: 24 }}>
                                                    <Select
                                                        defaultValue={undefined}
                                                        placeholder={<FormattedMessage defaultMessage={'Select an existing contract to override'} />}
                                                        options={contracts}
                                                        value={selectedContractId}
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => setIntegrationContractsToChange(c => {
                                                            const contracts = cloneDeep(c);
                                                            const contract = contracts.findIndex(cc => cc.integrationContract == parseInt(i));
                                                            contracts[contract].sunkhroContract = e;
                                                            return contracts;
                                                        })}
                                                    //TODO: Render custom Dropdown to show exactMatch and overlappingMatch
                                                    />
                                                </Col>
                                                <Col xs={{ span: 24 }}>
                                                    {
                                                        selectedContract ?
                                                            <div style={{ fontSize: 14, fontStyle: 'italic', color: 'var(--text-secondary)', textAlign: 'end' }}>
                                                                <p>
                                                                    {
                                                                        selectedContract?.contract_expiry_date ?
                                                                            <FormattedMessage defaultMessage={'From {start} to {end}'} description='Integration contract conflict' values={{ start: moment(selectedContract.date_in_report).format(getFormat('DATE')), end: moment(selectedContract.contract_expiry_date).format(getFormat('DATE')) }} />
                                                                            :
                                                                            <FormattedMessage defaultMessage={'From {start}'} description='Integration contract conflict' values={{ start: moment(selectedContract.date_in_report).format(getFormat('DATE')) }} />
                                                                    }
                                                                </p>
                                                                <p><FormattedMessage defaultMessage={'Rate of {rate}%'} values={{ rate: selectedContract.work_rate }} /></p>
                                                            </div>
                                                            :
                                                            undefined
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Modal>
        </>
    );
};
export default forwardRef(UsersManagement);