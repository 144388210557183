import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined, LeftOutlined, PlusCircleOutlined, PlusOutlined, QuestionCircleOutlined, RightOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Checkbox, Col, Collapse, Divider, Empty, InputNumber, message, Modal, Popconfirm, Row, Select, Space, Spin, Steps, Switch, Tag, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import toFinite from 'lodash/toFinite';
import * as momentJs from 'moment';
import { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import React, { ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import { loadDepartments, loadTypesOfVehicle } from '../../store/actions/configurations';
import { EventDataType, PlanningEventOwner } from '../../utils/enumerations';
import Network from '../../utils/network';
import { GenericFile, Group, StaffType, UserSummary, ValidateFile } from '../../utils/types/generalTypes';
import { BreakTime, BreakTimeEdit, EventAvailability, EventCanBeCreatedOrEditedBody, EventCopyErrorCodes, EventCreateError, PlanningEffectivePeriod, PlanningEvent, PlanningTemplate, Reminder } from '../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, LocationDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import { alert, checkRBACRule, colorIsBright, convertNetworkEffectivePeriodsToPlanningEffectivePeriods, convertNetworkReminderToReminder, convertNetworkTemplateToPlanningTemplate, displayErrorMessage, findSimilarColors, getCaseAndPlural, getWeekdayText, isNullOrEmpty, momentDurationFormat, openGoogleMapsRoute, roundDecimals, searchIgnoreCaseAndAccentInsensitive, showNotification } from '../../utils/utils';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
// import CircleButton from '../common/fields/circleButton';
// import { RiPinDistanceFill } from 'react-icons/ri';
import * as Sentry from "@sentry/react";
import isEqual from 'react-fast-compare';
import { AiOutlinePicture } from 'react-icons/ai';
import { MdAttachFile } from 'react-icons/md';
import { RiPinDistanceFill } from 'react-icons/ri';
import { FormattedMessage, injectIntl } from 'react-intl';
import { loadPois } from '../../store/actions/location';
import { toggleLoading } from '../../store/actions/user';
import { CaseType, MOMENT_FORMAT_TO_NETWORK } from '../../utils/constants';
import getFormat from '../../utils/Lang';
import { NetworkReminder } from '../../utils/types/networkTypes';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import ColorChooser from '../common/fields/colorChooser';
import DateTimePicker, { DateTimePickerType, DateTimePickerVersion } from '../common/fields/dateTimePicker';
import DeleteButton from '../common/fields/deleteButton';
import Anticon from '../common/general/anticon';
import SpaceContent from '../common/general/spaceContent';
import { CreationCallback } from '../planningNew/tabs/planning';
import ReminderModal, { automaticDurationTypeSelection } from './reminderModal';
import UploadModal from './uploadModal';

const moment = extendMoment(momentJs);
type ReduxProps = ConnectedProps<typeof connector>;

export interface TemplateFileCallback {
    (files: GenericFile[] | undefined): void;
}
interface IProps {
    currentEvent: PlanningEvent;
    canEditStartDate: boolean;
    onEdit: (errors: EventCreateError[], event: PlanningEvent, sendMessage?: boolean, forceUpdate?: boolean, skipCheckAvailability?: boolean) => void;
    onCreate: (errors: EventCreateError[], event: PlanningEvent, userIds: number[], sendMessage?: boolean, asTemplate?: boolean, forceCreate?: boolean, skipCheckAvailability?: boolean, callback?: CreationCallback) => void;
    onCancel: () => void;
    loading: boolean;
    openSettings: () => void;
    modalKey: string;
    loadFileFromTemplate?: number;
}

interface State {
    initCurrentEvent: PlanningEvent;
    userIds: number[];
    groupIds: number[];
    currentStep: number;
    currentTemplate: PlanningTemplate | undefined;
    currentEvent: PlanningEvent;
    untilShowTime: boolean;
    periods: PlanningEffectivePeriod[];
    asTemplate: boolean;
    sendMessage: boolean;
    isCreate: boolean;
    // breakTimes?: BreakTime[];
    editBreakTime: BreakTimeEdit;
    editBreakTimeError: boolean;
    filterByType: boolean;
    filterByUserAvailable: boolean;
    staffTypes?: StaffType[];

    distanceDisabled: boolean;
    loadingDistance: boolean;
    distanceDuration?: number;

    errorWithDates: boolean;
    errorWithUsers: boolean;

    addBreaktime: boolean;
    editReminder?: Reminder;
    newReminderIndex: number;
    addReminder: boolean;
    color: string;
    openFilter: boolean;


    usersAvailable?: UserSummary[];
    usersAvailabilityEvent?: EventAvailability;
    usersAvailabilityEventLoading: boolean;
    popConfirmAvailabilityError: boolean;
    errorWithSelectedUsers: {
        state: boolean;
        messages: string | string[] | undefined;
    };

    addDocuments: boolean;
    addImages: boolean;
    files: GenericFile[];
    loadingFiles: boolean;
    filesToValidate: ValidateFile[];
    imagesToValidate: ValidateFile[];

    loadingFromTemplate: boolean;

    forceCreate: boolean;
    createErrors: EventCreateError[];
}

type Props = IProps & LocationDispatchProps & ConfigurationsDispatchProps & ReduxProps & IntlProps;

/**
 * Component that represent the modal to create and edit an event
 */
class EditEventModal extends React.Component<Props, State> {

    titleInput: any = undefined;

    constructor(props: Props) {
        super(props);

        let distanceDisabled = true;
        if (this.props.currentEvent?.id && this.props.currentEvent.basePoi && this.props.currentEvent.poi) distanceDisabled = false;

        this.state = {
            newReminderIndex: -1,
            filesToValidate: [],
            imagesToValidate: [],
            files: [],
            loadingFiles: false,
            addDocuments: false,
            addImages: false,
            addReminder: false,
            color: (this.props.currentEvent.color && this.props.currentEvent.color.color) ? this.props.currentEvent.color?.color : 'var(--primary-color)',
            openFilter: false,
            initCurrentEvent: cloneDeep(this.props.currentEvent),
            userIds: this.props.currentEvent.userId ? [this.props.currentEvent.userId] : [],
            groupIds: this.props.currentEvent.groupId ? [this.props.currentEvent.groupId] : [],
            currentStep: 0,
            currentTemplate: undefined,
            currentEvent: cloneDeep(this.props.currentEvent),
            untilShowTime: true,
            periods: [],
            asTemplate: false,
            sendMessage: false,
            isCreate: !this.props.currentEvent.id,
            editBreakTime: {
                startDate: undefined,
                endDate: undefined,
            },
            editBreakTimeError: false,
            filterByType: true,
            filterByUserAvailable: (this.props.currentEvent.lookingForUserAvailability == null) ? true : this.props.currentEvent.lookingForUserAvailability,
            distanceDisabled: distanceDisabled,
            loadingDistance: false,
            errorWithDates: false,
            errorWithUsers: false,
            addBreaktime: false,
            usersAvailable: undefined,
            errorWithSelectedUsers: { state: false, messages: undefined },
            usersAvailabilityEventLoading: false,
            popConfirmAvailabilityError: false,
            usersAvailabilityEvent: this.props.currentEvent ? { startDate: this.props.currentEvent.startDate, endDate: this.props.currentEvent.endDate } : undefined,
            loadingFromTemplate: false,
            forceCreate: false,
            createErrors: []
            // breakTimes: this.props.currentEvent.breakTimes,
        };
    }

    componentDidMount() {
        if (this.state.currentEvent.owner === PlanningEventOwner.User && this.state.userIds.length > 0) this.getUserEffectivePeriods(this.state.currentEvent.userId!);
        this.props.loadPois();
        this.props.loadTypesOfVehicle();
        this.props.loadDepartments();
        this.loadUsersAvailable();
        this.loadEventFiles();

        Network.getStaffType().then(
            response => {
                this.setState({ staffTypes: response });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the abilities' }), "error")
        );
        if (this.props.loadFileFromTemplate !== undefined){
            this.getTemplateFiles(this.props.loadFileFromTemplate, async (files) => {
                if (files) {
                    const validateFiles = await Promise.all(files.map(async f => this.generateFilesToValidateFromTemplate(f)));
                    const filesToValidate = validateFiles.filter(f => f.res_type === 'FLE');
                    const imagesToValidate = validateFiles.filter(f => f.res_type === 'IMG');
                    this.setState({ filesToValidate, imagesToValidate });
                } else {
                    this.setState({ filesToValidate: [], imagesToValidate: [] });
                }
            });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // if the current event color have been updated in settings, update it in current event
        const { currentEvent } = this.state;
        if (prevProps.settings !== this.props.settings && this.props.settings.colors && currentEvent.color) {
            const color = this.props.settings.colors.find(c => c.id === currentEvent.color!.id);
            if (!color) {
                //if the color was deleted, set default
                currentEvent.color = undefined;
                this.setState({ currentEvent });
            } else if (color !== currentEvent.color) {
                //otherwise if the color is not the same, update it
                currentEvent.color = color;
                this.setState({ currentEvent });
            }
        }
        if (this.state.filterByUserAvailable && this.state.usersAvailabilityEvent !== undefined &&
            (prevState.usersAvailable === undefined || !isEqual(prevState.usersAvailabilityEvent, this.state.usersAvailabilityEvent)) &&
            this.state.usersAvailabilityEvent.startDate <= this.state.usersAvailabilityEvent.endDate && !this.state.usersAvailabilityEventLoading) {
            this.loadUsersAvailable();
        }

        // focus on the title input
        if (!this.titleInput) {
            this.titleInput = document.getElementById('planning-title-input');
            this.titleInput?.focus();
        }
    }

    removeEventReminder = (reminder: Reminder) => {
        if (reminder) {
            if (reminder && this.state.currentEvent.id) {
                Network.removeEventReminder(reminder.id, this.state.currentEvent.id).then(
                    (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                        if (response.error === false) {
                            const curEvent = this.state.currentEvent;
                            curEvent.reminders = curEvent.reminders?.filter(r => r.id !== response.data.id);

                            this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
                        } else {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the reminder' }), "warning");
                        }
                    },
                    () => {
                        this.setState({ loadingFiles: false });
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the reminder' }), "warning");
                    }
                );
            } else {
                const curEvent = this.state.currentEvent;
                console.log('PLOUUUF', curEvent);
                curEvent.reminders = curEvent.reminders?.filter(r => r.id !== reminder.id);

                this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
            }
        }
    };

    saveEventReminder = (reminder: Reminder) => {
        const { intl } = this.props;
        if (reminder) {
            if (this.state.currentEvent.id) {
                if (reminder.id > 0 && reminder.id !== undefined) {
                    Network.updateEventReminder(reminder.id, this.state.currentEvent.id, reminder.minBeforeEventStart).then(
                        (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                            if (response.error === false) {
                                const reminder = convertNetworkReminderToReminder(response.data);
                                const curEvent = this.state.currentEvent;
                                const findReminderId = curEvent.reminders?.findIndex(r => r.id === response.data.id);
                                if (findReminderId === undefined || findReminderId < 0) {
                                    curEvent.reminders?.push(reminder);
                                } else {
                                    if (!curEvent.reminders) {
                                        curEvent.reminders = [reminder];
                                    } else {
                                        curEvent.reminders[findReminderId] = reminder;
                                    }
                                }
                                this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
                            } else {
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the reminder' }), "warning");
                            }
                        },
                        () => {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the reminder' }), "warning");
                        }
                    );
                } else {
                    Network.addEventReminder(this.state.currentEvent.id, reminder.minBeforeEventStart).then(
                        (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                            if (response.error === false) {
                                const reminder = convertNetworkReminderToReminder(response.data);
                                const curEvent = this.state.currentEvent;
                                if (!curEvent.reminders) {
                                    curEvent.reminders = [reminder];
                                } else {
                                    curEvent.reminders.push(reminder);
                                }
                                this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
                            } else {
                                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the reminder' }), "warning");
                            }
                        },
                        () => {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the reminder' }), "warning");
                        }
                    );
                }
            } else {
                const curEvent = this.state.currentEvent;
                const findReminderId = (reminder !== undefined && reminder.id !== undefined) ? curEvent.reminders?.findIndex(r => r.id === reminder.id) : -1;
                if (findReminderId === undefined || findReminderId < 0) {
                    if (curEvent.reminders) {
                        curEvent.reminders.push(reminder);
                    } else {
                        curEvent.reminders = [reminder];
                    }
                } else {
                    if (!curEvent.reminders) {
                        curEvent.reminders = [reminder];
                    } else {
                        curEvent.reminders[findReminderId] = reminder;
                    }
                }

                this.setState({ currentEvent: curEvent, editReminder: undefined, addReminder: false });
            }

        }

    };

    loadEventFiles = () => {
        if (!this.state.loadingFiles) {
            this.setState({ loadingFiles: true }, () => {
                if (this.state.currentEvent && this.state.currentEvent.id) {
                    Network.getEventFiles(this.state.currentEvent.id).then(
                        (response) => {
                            if (response.error === false && response.data !== undefined) {
                                this.setState({ loadingFiles: false, files: response.data });
                            } else {
                                this.setState({ loadingFiles: false });
                            }
                        },
                        () => {
                            this.setState({ loadingFiles: false });
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                        }
                    );
                } else {
                    this.setState({ loadingFiles: false });
                }

            });

        }
    };

    loadUsersAvailable = () => {
        const { usersAvailabilityEventLoading, usersAvailabilityEvent, filterByUserAvailable } = this.state;
        if (filterByUserAvailable && !usersAvailabilityEventLoading && usersAvailabilityEvent !== undefined) {
            this.setState({ usersAvailabilityEventLoading: true }, () => {
                if (usersAvailabilityEvent !== undefined) {
                    Network.getUsersAvailabilities(usersAvailabilityEvent).then(
                        (response) => {
                            this.setState({ usersAvailable: response, usersAvailabilityEventLoading: false });
                        },
                        () => {
                            this.setState({ usersAvailabilityEventLoading: false });
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the availabilities' }), "warning");
                        }
                    );
                } else {
                    this.setState({ usersAvailabilityEventLoading: false });
                }
            });

        }
    };

    checkEventAvailability = async (event: PlanningEvent, userIds: number[]): Promise<boolean> => {
        this.props.toggleLoading!(true);
        return await Network.eventCheckAvailability(event, userIds).then(
            response => {
                this.props.toggleLoading!(false);

                if (response.length > 0) {

                    message.error(this.props.intl.formatMessage({ defaultMessage: 'No availabilities' }));

                    return false;

                } else {
                    return true;
                }
            },
            () => {
                this.props.toggleLoading!(false);
                return false;
            }
        );
    };

    getUserEffectivePeriods = (userId: number): void => {
        Network.getEffectivePeriods(userId).then(
            response => this.setState({ periods: convertNetworkEffectivePeriodsToPlanningEffectivePeriods(response) }),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the periods' }), "warning")
        );
    };

    getTemplateFiles = (templateId: number, callback: TemplateFileCallback) => {
        if (templateId) {
            Network.getTemplateFiles(templateId, true).then(
                (response) => {
                    if (response.error === false && response.data !== undefined) {
                        callback(response.data);
                    } else {
                        callback(undefined);
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                    }
                },
                () => {
                    callback(undefined);
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                }
            );
        } else {
            callback(undefined);
        }
    };

    generateFilesToValidateFromTemplate = async (f: GenericFile) => {
        let file = undefined;
        if (f.res_type === 'IMG') {
            const blob = await fetch(f.auth_url).then(r => r.blob());
            file = new File([blob], f.file_name);
        } else {
            file = new File([], f.file_name);
        }

        return ({ fileId: f.id, index: f.id, file_name: f.file_name, res_type: f.res_type, expiryDate: f.expiry_date, type: "ValidateFile", orgiginalFile: file } as ValidateFile);

    };

    loadTemplate = (templateId?: number) => {
        if (templateId !== undefined) {
            this.setState({ loadingFromTemplate: true });
            Network.getTemplate(templateId).then(
                (response) => {
                    if (response.error) {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the template to load' }), "error");
                    } else {
                        const template = convertNetworkTemplateToPlanningTemplate(response.data);

                        this.getTemplateFiles(templateId, async (files) => {
                            if (files) {
                                const validateFiles = await Promise.all(files.map(async f => this.generateFilesToValidateFromTemplate(f)));
                                const filesToValidate = validateFiles.filter(f => f.res_type === 'FLE');
                                const imagesToValidate = validateFiles.filter(f => f.res_type === 'IMG');
                                this.setState({ filesToValidate, imagesToValidate });
                            } else {
                                this.setState({ filesToValidate: [], imagesToValidate: [] });
                            }
                        });
                        let currentEvent = cloneDeep(this.state.currentEvent);
                        const startDate = currentEvent.startDate.clone();

                        //clone event
                        const diffSeconds = template.endDate.diff(template.startDate, 'seconds');
                        currentEvent = cloneDeep(template);
                        currentEvent.id = undefined;
                        currentEvent.isDraggable = true;
                        currentEvent.isGlobal = false;

                        currentEvent.startDate = startDate.clone().hours(template.startDate.hours()).minutes(template.startDate.minutes()).seconds(template.startDate.seconds());
                        currentEvent.endDate = currentEvent.startDate.clone().add(diffSeconds, 'seconds');

                        if (template.breakTimes && template.breakTimes.length > 0) {
                            let breakTimes: BreakTime[] = [];
                            let breaktimeTmp: BreakTime;
                            template.breakTimes?.forEach(b => {
                                breaktimeTmp = cloneDeep(b);

                                breaktimeTmp.startDate = currentEvent.startDate.clone().add(breaktimeTmp.startDate.diff(template.startDate, "seconds"), "seconds");
                                breaktimeTmp.endDate = currentEvent.startDate.clone().add(breaktimeTmp.endDate.diff(template.startDate, "seconds"), "seconds");

                                breakTimes.push(breaktimeTmp);
                            });

                            breakTimes = breakTimes.sort((a, b) => a.startDate.diff(b.startDate));

                            currentEvent.breakTimes = breakTimes;
                        }

                        this.setState({ currentEvent, currentTemplate: template, sendMessage: template.sendMessage === undefined ? false : template.sendMessage });
                    }
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the template to edit' }), "error");
                }
            ).finally(() => this.setState({ loadingFromTemplate: false }));

        } else {
            this.setState({ currentEvent: this.state.initCurrentEvent, currentTemplate: undefined });

            templateId !== undefined && showNotification(this.props.intl.formatMessage({ defaultMessage: 'The template can not be found' }), "error");
        }
    };

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */

    dataChanged = (type: EventDataType, value: any) => {
        const { currentEvent } = this.state;
        let distanceDisabled = true;

        switch (type) {
            case EventDataType.Title:
                currentEvent.title = value.target.value;
                break;
            case EventDataType.Description:
                currentEvent.description = value.target.value;
                break;
            case EventDataType.UserId:
                if (this.state.isCreate) {
                    this.setState({ userIds: value });
                    if (value.length === 1) this.getUserEffectivePeriods(value[0]);
                } else {
                    currentEvent.userId = value;
                    if (value) this.getUserEffectivePeriods(value);
                }
                break;
            case EventDataType.GroupId:
                if (this.state.isCreate) {
                    this.setState({ groupIds: value });
                } else {
                    currentEvent.groupId = value;
                }
                break;
            case EventDataType.Range:
                currentEvent.startDate = moment(value[0]);
                currentEvent.endDate = moment(value[1]);
                if (currentEvent.endDate.isBefore(currentEvent.endDate, "second")) {
                    currentEvent.endDate = currentEvent.startDate.clone().add(1, "hour");
                }
                break;
            case EventDataType.Owner:
                currentEvent.owner = value.target.value;
                currentEvent.isGlobal = value.target.value === PlanningEventOwner.Global;
                if (this.state.isCreate) {
                    this.setState({ userIds: [], groupIds: [] });
                } else {
                    currentEvent.userId = undefined;
                    currentEvent.groupId = undefined;
                }
                break;
            case EventDataType.IsDraggable:
                currentEvent.isDraggable = value;
                break;
            case EventDataType.Color:
                currentEvent.color = this.props.settings.colors!.find(c => c.id === value);
                break;
            case EventDataType.AsTemplate:
                this.setState({ asTemplate: value.target.checked });
                break;
            case EventDataType.SendMessage:
                this.setState({ sendMessage: value.target.checked });
                break;
            case EventDataType.TypeOfDay:
                currentEvent.typeOfDay = this.props.typesOfDay!.find(t => t.id === value);
                if (currentEvent.typeOfDay != undefined) {
                    currentEvent.typeOfDayOff = undefined;
                }
                break;
            case EventDataType.TypeOfDayOff:
                currentEvent.typeOfDayOff = this.props.typesOfDayOff!.find(t => t.id === value);
                if (currentEvent.typeOfDayOff !== undefined) {
                    currentEvent.typeOfDay = undefined;
                }
                break;
            case EventDataType.IgnoreTimeClock:
                currentEvent.ignoreTimeClock = value;
                break;
            case EventDataType.StaffType:
                currentEvent.staffType = this.state.staffTypes!.find(t => t.id === value);
                break;
            case EventDataType.Poi:
                currentEvent.poi = this.props.pois?.data?.find(t => t.id === value);
                if (currentEvent.poi === undefined) {
                    currentEvent.distance = undefined;
                }

                if (currentEvent.poi && currentEvent.basePoi) distanceDisabled = false;
                if (distanceDisabled !== this.state.distanceDisabled) this.setState({ distanceDisabled });
                break;
            case EventDataType.Department:
                currentEvent.department = this.props.departments.data.find(department => department.id === value);
                break;
            case EventDataType.Project:
                currentEvent.project = this.props.project!.find(t => t.id === value);
                break;

            case EventDataType.BasePoi:
                currentEvent.basePoi = this.props.basePois?.data?.find(t => t.id === value);
                if (currentEvent.basePoi === undefined) {
                    currentEvent.distance = undefined;
                }

                if (currentEvent.poi && currentEvent.basePoi) distanceDisabled = false;
                if (distanceDisabled !== this.state.distanceDisabled) this.setState({ distanceDisabled });
                break;
            case EventDataType.Vehicle:
                currentEvent.vehicle = this.props.vehicles?.data?.find(t => t.id === value);
                break;
            case EventDataType.Distance:
                currentEvent.distance = toFinite(value);
                break;
        }

        this.setState({ currentEvent });
    };

    /**
     * Render method for the weekday select
     * @param props the props of the select option
     * @returns the react element to render
     */
    renderWeekdaySelect = (props: any): ReactElement<number> => (
        <Tag className="select-tags" closable={props.closable} onClose={props.onClose}>
            {getWeekdayText(props.value)}
        </Tag>
    );

    /**
     * Render method for the owner selects
     * @param props the props of the select option
     * @returns the react element to render
     */
    renderOwnerSelect = (props: any): ReactElement<number> => (
        <Tag className="select-tags" closable={props.closable} onClose={props.onClose}>
            {props.label}
        </Tag>
    );

    /**
     * Check if a date must be disabled
     * @param date the current date to verify
     * @returns true if the date must be disabled, false otherwise
     */
    disableDate = (date: Moment): boolean => {
        const { periods, currentEvent } = this.state;

        if ((this.state.isCreate && this.state.userIds.length > 0) || (!this.state.isCreate && currentEvent.userId)) {
            let atLeastOnce = false;
            for (let i = 0; i < periods.length; ++i) {
                // if (date.within(moment.range(periods[i].startDate, periods[i].endDate))) return false;
                if (date.isBetween(periods[i].startDate, periods[i].endDate, "days", "[]")) {
                    atLeastOnce = true;
                }
            }
            return !atLeastOnce;
        } else {
            return false;
        }
    };

    /**
     * Check if a time must be disabled
     * @param date the current date to verify
     * @returns an object containing the different disabled times
     */
    disableTime = (date: Moment | null) => {
        const { settings } = this.props;
        if (date !== null && settings.startHourOfDay && settings.endHourOfDay) {
            return {
                disabledHours: () => range(0, settings.startHourOfDay!.hours()).concat(range(settings.endHourOfDay!.hours() + 1, 24)),
                disabledMinutes: (hours: number) => {
                    if (this.props.settings.endHourOfDay && hours === this.props.settings.endHourOfDay.hours()) {
                        return range(this.props.settings.endHourOfDay.minutes() + 1, 59);
                    } else {
                        return [];
                    }
                }
            };
        }
        return {};
    };

    /**
     * Cancel the creation, edition
     */
    onCancel = (): void => {
        this.setState({ currentStep: 0, currentTemplate: undefined });
        this.props.onCancel();
    };

    /**
     * Called when the user click on the OK button
     */
    onClickBeforConfirm = async (): Promise<void> => {
        const { currentEvent, createErrors } = this.state;
        // currentEvent.breakTimes = this.state.breakTimes;

        const create = () => {
            if (this.state.isCreate)
                this.props.onCreate(createErrors, currentEvent, this.state.userIds, this.state.sendMessage, this.state.asTemplate, false, true, this.validateFiles);
            else
                this.props.onEdit(createErrors, currentEvent, this.state.sendMessage, false, true);
        };

        //if there is no owner, display an error message (create mode)
        if (!currentEvent.isGlobal && (this.state.isCreate ? (this.state.userIds.length === 0 && this.state.groupIds.length === 0) : (!currentEvent.userId && !currentEvent.groupId)))
            displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please select an user or a group' }), "planning-modal-ok-button");
        //if there is no title, display an error message
        else if (currentEvent.title.length === 0)
            displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please give a name to the event' }), "planning-modal-ok-button");
        else {
            this.setState({ currentStep: 0, currentTemplate: undefined });

            const EventCanCreateOrEditedBody: EventCanBeCreatedOrEditedBody = {
                data: this.state.userIds.map((id) => ({
                    endDate: currentEvent.endDate.format(MOMENT_FORMAT_TO_NETWORK),
                    startDate: currentEvent.startDate.format(MOMENT_FORMAT_TO_NETWORK),
                    userId: id,
                    eventId: currentEvent.id
                }))
            };

            if (this.state.forceCreate)
                create();
            else
                Network.canEventBeCreatedOrEdited(EventCanCreateOrEditedBody).then(
                    (response) => {
                        if (response.data.length > 0) {
                            const eventErrors: EventCreateError[] = [];
                            response.data.forEach(event => {
                                const foundEventError = eventErrors[eventErrors.findIndex(eb => eb.user.id === event.userId)];
                                const error = ({
                                    type: event.errorType,
                                    codes: event.errorValues
                                });

                                if (foundEventError) {
                                    foundEventError.errors.push(error);
                                }
                                else {
                                    const user = this.props.users.find(u => u.id === event.userId);
                                    if (user)
                                        eventErrors.push({
                                            user,
                                            event: {
                                                endDate: moment(event.endDate),
                                                startDate: moment(event.startDate),
                                                id: event.eventId
                                            },
                                            errors: [error]
                                        });
                                }
                            });

                            this.setState({ createErrors: eventErrors });
                        }
                        else {
                            create();
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while veryfing the creation or modification of the event' }), 'error');
                    }
                );
        }
    };

    validateFiles = async (eventId: number) => {
        if (eventId != null) {
            if (this.state.filesToValidate.length > 0) {
                const flatFilesToValidate = this.state.filesToValidate.map(fTV => fTV.fileId);
                await Network.validateEventFiles(eventId, this.state.filesToValidate).then(
                    (response) => {
                        if (response.error === false && response.data) {
                            const actualFilesToValidate = cloneDeep(this.state.filesToValidate);
                            this.setState({ files: response.data, filesToValidate: actualFilesToValidate.filter(fTV => !flatFilesToValidate.includes(fTV.fileId)) });
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the files' }), "warning");
                    }
                );
            }
            if (this.state.imagesToValidate.length > 0) {
                const flatImagesToValidate = this.state.imagesToValidate.map(fTV => fTV.fileId);
                Network.validateEventFiles(eventId, this.state.imagesToValidate).then(
                    (response) => {
                        if (response.error === false && response.data) {
                            const actualImagesToValidate = cloneDeep(this.state.imagesToValidate);
                            this.setState({ files: response.data, imagesToValidate: actualImagesToValidate.filter(fTV => !flatImagesToValidate.includes(fTV.fileId)) });
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the images' }), "warning");
                    }
                );
            }
        }
    };

    onForceConfirm = async (): Promise<void> => {
        const { currentEvent, createErrors } = this.state;
        // currentEvent.breakTimes = this.state.breakTimes;

        //if there is no owner, display an error message (create mode)
        if (!currentEvent.isGlobal && (this.state.isCreate ? (this.state.userIds.length === 0 && this.state.groupIds.length === 0) : (!currentEvent.userId && !currentEvent.groupId))) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please select an user or a group' }), "planning-modal-ok-button");
        //if there is no title, display an error message
        else if (currentEvent.title.length === 0) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please give a name to the event' }), "planning-modal-ok-button");
        else {
            this.setState({ currentStep: 0, currentTemplate: undefined });
            this.state.isCreate ? this.props.onCreate(createErrors, currentEvent, this.state.userIds, this.state.sendMessage, this.state.asTemplate, true, false, this.validateFiles) : this.props.onEdit(createErrors, currentEvent, this.state.sendMessage, true, false);
            this.setState({ popConfirmAvailabilityError: false });

        }
    };

    onDone = (): void => {
        const { currentEvent, createErrors } = this.state;
        // currentEvent.breakTimes = this.state.breakTimes;

        //if there is no owner, display an error message (create mode)
        if (!currentEvent.isGlobal && (this.state.isCreate ? (this.state.userIds.length === 0 && this.state.groupIds.length === 0) : (!currentEvent.userId && !currentEvent.groupId))) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please select an user or a group' }), "planning-modal-ok-button");
        //if there is no title, display an error message
        else if (currentEvent.title.length === 0) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please give a name to the event' }), "planning-modal-ok-button");
        else {
            this.setState({ currentStep: 0, currentTemplate: undefined });
            this.state.isCreate ? this.props.onCreate(createErrors, currentEvent, this.state.userIds, this.state.sendMessage, this.state.asTemplate, false, false, this.validateFiles) : this.props.onEdit(createErrors, currentEvent, this.state.sendMessage);
            this.setState({ popConfirmAvailabilityError: false });
        }
    };

    checkBreaktimeFields = () => {
        const { editBreakTime } = this.state;
        return (editBreakTime === undefined || editBreakTime.title === undefined || !editBreakTime.startDate || !editBreakTime.endDate);
    };

    validateBreakTime = () => {
        const { currentEvent, editBreakTime } = this.state;
        if (editBreakTime === undefined || editBreakTime.title === undefined || !editBreakTime.startDate || !editBreakTime.endDate) {
            this.setState({ editBreakTimeError: true });
            return false;
        } else {

            let minId = currentEvent.breakTimes?.reduce((prev, curr) => {
                return prev < curr.id ? prev : curr.id;
            }, 0.0);
            if (minId === undefined || minId > -1) {
                minId = -1;
            } else {
                minId--;
            }

            const breakTimesTmp: BreakTime = {
                active: true,
                title: editBreakTime.title,
                startDate: editBreakTime.startDate.seconds(0).milliseconds(0),
                endDate: editBreakTime.endDate.seconds(0).milliseconds(0),
                isPaid: editBreakTime.isPaid ? editBreakTime.isPaid : false,
                totalHours: roundDecimals(editBreakTime.endDate.diff(editBreakTime.startDate, 'hours', true)),
                totalSeconds: roundDecimals(editBreakTime.endDate.diff(editBreakTime.startDate, 'seconds', true)),
                isClocked: false,
                id: editBreakTime.id ?? minId,
            };

            if (currentEvent.breakTimes === undefined) {
                currentEvent.breakTimes = [breakTimesTmp];
            } else {
                const breaktimeToChangeId = currentEvent.breakTimes.findIndex(b => b.id === breakTimesTmp.id);
                if (breaktimeToChangeId >= 0) {
                    currentEvent.breakTimes[breaktimeToChangeId] = breakTimesTmp;
                } else {
                    currentEvent.breakTimes.push(breakTimesTmp);
                }
            }

            this.setState({
                currentEvent,
                editBreakTime: {
                    startDate: undefined,
                    endDate: undefined,
                },
                editBreakTimeError: false
            });
            return true;
        }

    };

    genExtra = (index: number) => {
        return (
            <Space>
                <DeleteOutlined
                    onClick={() => {
                        const { currentEvent } = this.state;
                        currentEvent.breakTimes?.splice(index, 1);
                        this.setState({ currentEvent });
                    }}
                />
                <EditOutlined
                    onClick={(e) => {
                        e.stopPropagation();
                        const breaktime = this.state.currentEvent.breakTimes?.at(index);
                        if (!breaktime) {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The break can not be found' }), "error");
                            return;
                        }
                        this.setState({ addBreaktime: true, editBreakTime: cloneDeep(breaktime) });
                    }}
                />
            </Space>
        );
    };

    openGoogleMaps = () => {
        const { currentEvent } = this.state;
        if (currentEvent.poi && currentEvent.basePoi && currentEvent.poi.latitude && currentEvent.poi.longitude && currentEvent.basePoi.latitude && currentEvent.basePoi.longitude) {
            openGoogleMapsRoute(currentEvent.basePoi.latitude, currentEvent.basePoi.longitude, currentEvent.poi.latitude, currentEvent.poi.longitude);
        }
    };

    generateDistance = () => {
        const { currentEvent } = this.state;
        if (currentEvent.poi && currentEvent.basePoi && currentEvent.poi.latitude && currentEvent.poi.longitude && currentEvent.basePoi.latitude && currentEvent.basePoi.longitude) {
            this.setState({ loadingDistance: true });
            Network.generateDistance(currentEvent.basePoi.latitude.toString(), currentEvent.basePoi.longitude.toString(), currentEvent.poi.latitude.toString(), currentEvent.poi.longitude.toString()).then(
                success => {
                    if (!success.error) {
                        // currentEvent.distance = roundDecimals(success.distance / 0.621371, 0, MathTypeRound.CEIL);
                        // const distanceDuration = roundDecimals(success.time / 60, 0, MathTypeRound.CEIL);
                        currentEvent.distance = success.distance;
                        const distanceDuration = Math.round(success.time);
                        this.setState({ currentEvent, loadingDistance: false, distanceDuration });
                    } else {
                        Sentry.captureMessage("DIS0 : " + success);
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while retrieving the distance' }), "error");
                        this.setState({ loadingDistance: false, distanceDuration: undefined });
                    }
                },
                error => {
                    Sentry.captureMessage("DIS1 : " + error.message ? error.message : String(error));
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while retrieving the distance' }), "error");
                    this.setState({ loadingDistance: false, distanceDuration: undefined });
                }
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Please select a starting and a working location' }), "error");
        }
    };

    onChangeStartDate = (date: Moment | null) => {
        const { currentEvent } = this.state;
        if (date) {
            currentEvent.startDate = date;
            this.setState({ currentEvent });
        }
    };

    onChangeEndDate = (date: Moment | null) => {
        const { currentEvent } = this.state;
        if (date) {
            currentEvent.endDate = date;
            this.setState({ currentEvent });
        }
    };

    onBlur = () => {
        const { currentEvent } = this.state;
        if (currentEvent.startDate && currentEvent.endDate) {
            this.setState((state) => ({ usersAvailabilityEvent: { startDate: state.currentEvent.startDate ? state.currentEvent.startDate.clone() : currentEvent.startDate.clone(), endDate: state.currentEvent.endDate ? state.currentEvent.endDate.clone() : currentEvent.endDate.clone() } }));
        }
    };

    getNameByUid = (uid: number | undefined) => {
        if (!uid) return uid;
        const user = this.props.users?.find(u => u.id === uid);
        return user ? `${user.first_name} ${user.last_name}` : `(${uid})`;
    };

    getFilteredColors = (searchTitle?: string, searchColor?: string) => {
        let filteredColors = cloneDeep(this.props.settings.colors);
        if (isNullOrEmpty(filteredColors)) {
            return undefined;
        }

        if (searchTitle && searchTitle.length > 0) {
            filteredColors = filteredColors?.filter(color => searchIgnoreCaseAndAccentInsensitive(color.title, searchTitle));
        }

        if (searchColor && searchColor.length > 0 && filteredColors) {
            const listOfSimilarColorsIdx: number[] = findSimilarColors(searchColor, filteredColors.map(color => color.color), 80, true) as number[];
            filteredColors = listOfSimilarColorsIdx.map(c => {
                return filteredColors![c];
            });
        }

        return filteredColors;
    };

    checkBreaktimesOutsideOfEvent = () => {
        const { currentEvent } = this.state;
        return currentEvent.breakTimes?.some((breaktime) => breaktime.startDate.isBefore(currentEvent.startDate) || breaktime.endDate.isAfter(currentEvent.endDate));
    };

    /**
     * Render the content of the create/edit modal
     */
    renderModalContent = () => {
        const { Panel } = Collapse;
        const { currentEvent, isCreate, filterByType, filterByUserAvailable, usersAvailable } = this.state;
        const { canEditStartDate, currentUser, groups, intl } = this.props;
        const canManage = checkRBACRule(Rules.Planning.Management);
        const canManageUser = !this.props.currentEvent.id;
        // START "Filters by type"
        let empty = false;
        let emptyStr = intl.formatMessage({ defaultMessage: 'No users available' });
        let users = this.props.users;
        users = users.filter(u => u.visible !== false);
        if (users === undefined || users.length <= 0) {
            empty = true;
            emptyStr = intl.formatMessage({ defaultMessage: 'No active users' });

        }
        // Filter by StaffType
        !empty && filterByType && currentEvent.staffType && (users = users?.filter(u => u.staffType && u.staffType.length > 0 ? u.staffType.findIndex(st => st.id === currentEvent.staffType?.id) > -1 : false));
        if (!empty && (users === undefined || users.length <= 0)) {
            empty = true;
            emptyStr += ` ${intl.formatMessage({ defaultMessage: 'according to the selected ability' })}`;
        }
        // If the POI has no groups configured (before), no users available / Else filter by POI (with group)
        !empty && filterByType && currentEvent.poi && groups && groups.length === 0 ?
            users = []
            :
            filterByType && currentEvent.poi && currentEvent.poi.groups && currentEvent.poi.groups.length > 0 && (users = users?.filter(u => u.group_users?.find(gu => groups?.find(g => g.id === gu.group) !== undefined) !== undefined));

        if (!empty && (users === undefined || users.length <= 0)) {
            empty = true;
            emptyStr += ` ${intl.formatMessage({ defaultMessage: 'according to the selected location' })}`;
        }
        // END "Filters by type"

        // START "Filters by users avaialble"
        !empty && filterByUserAvailable && usersAvailable !== undefined && (users = users?.filter(u => usersAvailable.find(ua => ua.id === u.id)));
        if (!empty && (users === undefined || users.length <= 0)) {
            empty = true;
            emptyStr = intl.formatMessage({ defaultMessage: 'No user has availability in their planning' });
        }
        // END "Filters by users avaialble"


        let selectedUsersErr = false;
        const selectedUsersErrStrs: string[] = [];
        let selectedUsersIds: number[] | undefined = undefined;
        if (users && users.length <= 0) {
            if (isCreate && this.state.userIds && this.state.userIds.length > 0) {
                selectedUsersErr = true;
                selectedUsersErrStrs.push(intl.formatMessage({ defaultMessage: 'Selected users are not available' }));
            } else {
                if (currentEvent.userId) {
                    selectedUsersErr = true;
                    selectedUsersErrStrs.push(intl.formatMessage({ defaultMessage: 'The selected user is not available' }));
                }
            }

        } else {
            if (isCreate) {
                selectedUsersIds = this.state.userIds.filter(uid => {
                    if (users?.find(u => u.id === uid)) {
                        return true;
                    }
                    selectedUsersErr = true;
                    selectedUsersErrStrs.push(intl.formatMessage({ defaultMessage: 'The user {name} is no more available' }, { name: this.getNameByUid(uid) }));
                    return false;
                });
            } else if (users?.find(u => u.id === currentEvent.userId)) {
                selectedUsersIds = currentEvent.userId ? [currentEvent.userId] : undefined;
            } else {
                selectedUsersErr = true;
                selectedUsersErrStrs.push(intl.formatMessage({ defaultMessage: 'The user {name} is no more available' }, { name: this.getNameByUid(currentEvent.userId) }));
            }

        }
        if (canManageUser && (!users || users.length === 0) && this.state.errorWithUsers === false) {
            this.setState({ errorWithUsers: true });
        } else if (users && users.length > 0 && this.state.errorWithUsers === true) {
            this.setState({ errorWithUsers: false });
        } else if (filterByUserAvailable && (!selectedUsersIds || selectedUsersIds.length === 0) && this.state.errorWithSelectedUsers.state === false) {
            this.setState({ errorWithSelectedUsers: { state: true, messages: selectedUsersErrStrs } });
        }
        else if (selectedUsersIds && selectedUsersIds.length > 0 && this.state.errorWithSelectedUsers.state === true) {
            this.setState({ errorWithSelectedUsers: { state: false, messages: undefined } });
        }

        let groupsToAdd: Group[] = this.props.groups?.sort((a, b) => a.name! > b.name! ? 1 : -1) ?? [];
        const flatGroupsIds = currentUser && currentUser.role === 1 ? currentUser.can_read_only_all_planning ? groupsToAdd.map(g => g.id) : currentUser.groupsAdmin ? currentUser.groupsAdmin.map(groupA => groupA.group_id) : [] : groupsToAdd.map(g => g.id);
        groupsToAdd = groupsToAdd.filter(group => flatGroupsIds.includes(group.id));


        switch (this.state.currentStep) {
            case 0: {
                let colors: any = [<Select.Option label={intl.formatMessage({ defaultMessage: 'Default' })} value={-1} key={`planning-colors-default`}>{<span className="flex-row"><p><FormattedMessage defaultMessage={'Default'} /></p><p style={{ color: 'var(--primary-color)', fontSize: '30px', display: currentEvent.color ? undefined : 'none' }}>•</p></span>}</Select.Option>];
                if (this.props.settings.colors) {
                    colors = this.props.settings.colors!.map(c => <Select.Option label={c.title} value={c.id!} key={`planning-colors-${c.id}`}><span className="flex-row"><p>{c.title}</p><p style={{ fontSize: '30px', color: c.color, display: currentEvent.color?.id === c.id ? 'none' : undefined }}>•</p></span></Select.Option>);
                    colors.unshift(<Select.Option label={intl.formatMessage({ defaultMessage: 'Default' })} value={-1} key={`planning-colors-default`}>{<span className="flex-row"><p><FormattedMessage defaultMessage={'Default'} /></p><p style={{ color: 'var(--primary-color)', fontSize: '30px', display: currentEvent.color ? undefined : 'none' }}>•</p></span>}</Select.Option>);
                }

                return (
                    <Row gutter={[15, 15]}>
                        {
                            this.state.isCreate && this.props.templates!.length > 0 &&
                            <Col xs={{ span: 24 }} md={{ span: 12, offset: 12 }}>
                                <Select
                                    className="event-modal-data-entry"
                                    placeholder={<FormattedMessage defaultMessage={'Load data from a template'} />}
                                    allowClear
                                    showSearch
                                    onChange={(id: number) => this.loadTemplate(id)}
                                    value={this.state.currentTemplate ? this.state.currentTemplate.id as number : undefined}
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {this.props.templates!.map(t => <Select.Option label={t.title} key={`planning-templates-${t.id}`} value={t.id!}>{t.title}</Select.Option>)}
                                </Select>
                            </Col>
                        }
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Row style={{ marginBottom: '15px' }}>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title={<span><FormattedMessage defaultMessage={'Required information'} /><span style={{ color: 'red' }}>*</span></span>}>
                                        <Row gutter={[10, 10]}>
                                            <Col xs={{ span: 24 }}>
                                                {
                                                    canManageUser ?
                                                        <div className="planning-modal-section" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <p style={{ marginBottom: "3px" }}><FormattedMessage defaultMessage={'Compatible users'} /><sup><a href='#event-options'>(1)</a></sup> <Switch checked={this.state.filterByType} onChange={e => this.setState({ filterByType: e })} style={{ marginRight: '5px', float: "right" }} /></p>
                                                            <p style={{ marginBottom: "3px" }}><FormattedMessage defaultMessage={'Available users'} /><Switch checked={this.state.filterByUserAvailable} onChange={e => this.setState({ filterByUserAvailable: e })} style={{ marginRight: '5px', float: "right" }} /></p>

                                                            <Select
                                                                status={users && users.length === 0 ? 'error' : undefined}
                                                                mode={isCreate ? "multiple" : undefined}
                                                                allowClear
                                                                maxTagCount={'responsive'}
                                                                showSearch
                                                                disabled={empty}
                                                                placeholder={empty ? <FormattedMessage defaultMessage={'No users available'} /> : <FormattedMessage defaultMessage={'Please select a user'} />}
                                                                onChange={ids => this.dataChanged(EventDataType.UserId, ids)}
                                                                tagRender={this.renderOwnerSelect}
                                                                value={selectedUsersIds}
                                                                filterOption={true}
                                                                optionFilterProp="label"
                                                                dropdownRender={menu => (
                                                                    <>
                                                                        {menu}
                                                                        <Divider style={{ margin: '4px 0' }} />
                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <Button
                                                                                style={{ fontSize: '90%', fontStyle: 'italic' }}
                                                                                type='link'
                                                                                onClick={() => this.dataChanged(EventDataType.UserId, users.map(u => u.id))}>
                                                                                <FormattedMessage defaultMessage={'Select all users'} />
                                                                            </Button>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            >
                                                                {users?.map(u => <Select.Option label={`${u.last_name} ${u.first_name}`} key={`planning-users-${u.id}`} value={u.id}>{`${u.last_name} ${u.first_name}`}</Select.Option>)}
                                                            </Select>
                                                        </div>
                                                        :
                                                        (() => {
                                                            const user = this.props.users?.find(u => u.id === currentEvent.userId);
                                                            return (
                                                                <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", gap: '5px' }}>
                                                                    <p><FormattedMessage defaultMessage={'User'} /></p>
                                                                    <InputField
                                                                        disabled={true}
                                                                        style={{ maxWidth: '300px' }}
                                                                        value={`${user?.last_name} ${user?.first_name}`} />
                                                                </div>
                                                            );
                                                        })()
                                                }
                                            </Col>
                                            <Col xs={{ span: 24 }} style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", gap: '10px' }}>
                                                <p><FormattedMessage defaultMessage={'Title'} /></p>
                                                <InputField
                                                    id="planning-title-input"
                                                    style={{ maxWidth: '300px' }}
                                                    value={currentEvent.title}
                                                    placeholder={intl.formatMessage({ defaultMessage: 'Event name' })}
                                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(EventDataType.Title, e)} />
                                            </Col>
                                            <Col xs={{ span: 24 }}>
                                                <Divider plain orientation='left' orientationMargin={0} style={{ marginTop: '5px' }}><FormattedMessage defaultMessage={'Hours'} /></Divider>
                                                <DateTimePicker
                                                    valueFrom={currentEvent.startDate}
                                                    onChangeFrom={this.onChangeStartDate}
                                                    disableDatePickerFrom={canEditStartDate === false}
                                                    // disableDateFrom={this.disableDate}
                                                    // disableTimeFrom={this.disableTime}

                                                    valueTo={currentEvent.endDate}
                                                    onChangeTo={this.onChangeEndDate}
                                                    onBlur={this.onBlur}
                                                    // disableDateTo={this.disableDate}
                                                    // disableTimeTo={this.disableTime}

                                                    showTime={true}
                                                    type={DateTimePickerType.RANGE}
                                                    version={DateTimePickerVersion.FIELD}
                                                    setError={(error) => this.setState({ errorWithDates: error })}
                                                />
                                                {
                                                    this.checkBreaktimesOutsideOfEvent() &&
                                                    <Alert type='error' showIcon
                                                        style={{ fontSize: "80%", fontStyle: 'italic', marginTop: 10 }}
                                                        message={`Une ou plusieurs pauses sont en dehors de l'événement`}
                                                    />
                                                }
                                            </Col>
                                            {
                                                currentEvent.clocked && !currentEvent.clocked.isRefused && !currentEvent.clocked.isConfirmed && !currentEvent.ignoreTimeClock ?
                                                    <Col xs={{ span: 24 }}>
                                                        <Alert type="warning" showIcon
                                                            style={{ fontSize: "80%", fontStyle: 'italic' }}
                                                            message={<FormattedMessage defaultMessage={'The event has been clocked {confirmed} and is waiting validation by an administrator'} values={{ confirmed: currentEvent.clocked.isConfirmed && intl.formatMessage({ defaultMessage: 'and confirmed' }) }} />} />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                empty ?
                                                    <Col xs={{ span: 24 }}>
                                                        <Alert type="warning" showIcon
                                                            style={{ fontSize: "80%", fontStyle: 'italic' }}
                                                            message={emptyStr} />
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                selectedUsersErr ?
                                                    selectedUsersErrStrs.map((errorStr, i) => {
                                                        return (
                                                            <Col key={i} xs={{ span: 24 }}>
                                                                <Alert type="warning" showIcon
                                                                    style={{ fontSize: "80%", fontStyle: 'italic' }}
                                                                    message={errorStr} />
                                                            </Col>
                                                        );
                                                    })

                                                    : null
                                            }
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title={<FormattedMessage defaultMessage={'Details'} />}>
                                        <Row gutter={[10, 10]}>
                                            <Col xs={{ span: 24 }}>
                                                <span className="flex-row event-section-title">
                                                    <FormattedMessage defaultMessage={'Description'} />
                                                    <span>
                                                        <Badge
                                                            offset={[-3, 4]}
                                                            size={'small'}
                                                            style={{ border: 'none', boxShadow: 'none', zIndex: 100000 }}
                                                            count={this.props.currentEvent.id ? this.state.files.filter(f => f.res_type === 'IMG').length : this.state.imagesToValidate.filter(fTV => fTV.res_type === 'IMG').length}
                                                        >
                                                            <CircleButton size={'small'} style={{ border: 'none', background: 'none' }} icon={<Anticon icon={<AiOutlinePicture />} />} onClick={() => this.setState({ addImages: true })} />
                                                        </Badge>
                                                        <Badge
                                                            offset={[-3, 4]}
                                                            size={'small'}
                                                            style={{ border: 'none', boxShadow: 'none', zIndex: 100000 }}
                                                            count={this.props.currentEvent.id ? this.state.files.filter(f => f.res_type === 'FLE').length : this.state.filesToValidate.filter(fTV => fTV.res_type === 'FLE').length}
                                                        >
                                                            <CircleButton size={'small'} style={{ border: 'none', background: 'none' }} icon={<Anticon icon={<MdAttachFile />} />} onClick={() => this.setState({ addDocuments: true })} />
                                                        </Badge>
                                                    </span>
                                                </span>
                                                <InputField
                                                    type="textarea"
                                                    value={currentEvent.description}
                                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(EventDataType.Description, e)}
                                                    autoSize={{ minRows: 2 }} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col >
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Row style={{ marginBottom: '15px' }}>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title={<span id="event-options"><FormattedMessage defaultMessage={'Event options'} /> <sup>(1)</sup></span>}>
                                        <Row gutter={[10, 10]}>
                                            <Col xs={{ span: 10 }}>
                                                <span><FormattedMessage defaultMessage={'Ability'} /></span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    disabled={this.state.staffTypes === undefined || this.state.staffTypes.length <= 0}
                                                    onChange={(id: number) => this.dataChanged(EventDataType.StaffType, id)}
                                                    value={currentEvent.staffType?.id}
                                                    filterOption={true}
                                                    options={this.state.staffTypes?.map(t => ({ label: t.name, value: t.id }))}
                                                    optionFilterProp="label"
                                                />
                                            </Col>
                                            {
                                                checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) &&
                                                <>
                                                    <Col xs={{ span: 10 }}>
                                                        <span><FormattedMessage defaultMessage={'Workplace'} /></span>
                                                    </Col>
                                                    <Col xs={{ span: 14 }}>
                                                        <Select
                                                            className="planning-modal-input-100"
                                                            showSearch
                                                            allowClear
                                                            disabled={this.props.pois === undefined || this.props.pois.data === undefined || this.props.pois.data.length <= 0}
                                                            onChange={(id: number) => this.dataChanged(EventDataType.Poi, id)}
                                                            value={currentEvent.poi?.id}
                                                            filterOption={true}
                                                            optionFilterProp="label">
                                                            {this.props.pois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                        </Select>
                                                    </Col>
                                                </>
                                            }
                                            <Col xs={{ span: 10 }}>
                                                <span><FormattedMessage defaultMessage={'Type of day'} /></span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    disabled={this.props.typesOfDay === undefined || this.props.typesOfDay.length <= 0}
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.TypeOfDay, id)}
                                                    value={currentEvent.typeOfDay?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.typesOfDay?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                </Select>
                                            </Col>
                                            <Col xs={{ span: 24 }}>
                                                <Divider style={{ margin: '0' }} orientation="left" plain>
                                                    <FormattedMessage defaultMessage={'or'} />
                                                </Divider>
                                            </Col>
                                            <Col xs={{ span: 10 }}>
                                                <span><FormattedMessage defaultMessage={'Type of vacation'} /></span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    disabled={this.props.typesOfDayOff === undefined || this.props.typesOfDayOff.length <= 0}
                                                    onChange={(id: number) => this.dataChanged(EventDataType.TypeOfDayOff, id)}
                                                    value={currentEvent.typeOfDayOff?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.typesOfDayOff?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '15px' }}>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title={<FormattedMessage defaultMessage={'Extra'} />}>
                                        <Row gutter={[15, 15]}>
                                            {
                                                (checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) ||
                                                    checkRBACRule(Rules.ProjectV2.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length)) &&
                                                <>
                                                    <Col xs={{ span: 10 }}>
                                                        <span>{this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />}</span>
                                                    </Col>
                                                    <Col xs={{ span: 14 }}>
                                                        <Select
                                                            className="planning-modal-input-100"
                                                            showSearch
                                                            allowClear
                                                            disabled={this.props.project === undefined || this.props.project.length <= 0}
                                                            onChange={(id: number) => this.dataChanged(EventDataType.Project, id)}
                                                            value={currentEvent.project?.id}
                                                            filterOption={true}
                                                            optionFilterProp="label">
                                                            {this.props.project?.map(p => <Select.Option label={p.title} value={p.id!} key={`planning-project-${p.id}`}>{p.title}</Select.Option>)}
                                                        </Select>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                this.props.departments.updated && this.props.departments.data.length > 0 ?
                                                    <>
                                                        <Col xs={{ span: 10 }}>
                                                            <span><FormattedMessage defaultMessage={'Department'} /></span>
                                                        </Col>
                                                        <Col xs={{ span: 14 }}>
                                                            <Select
                                                                className="planning-modal-input-100"
                                                                showSearch
                                                                allowClear
                                                                onChange={(e: number) => this.dataChanged(EventDataType.Department, e)}
                                                                placeholder={<FormattedMessage defaultMessage={'Department'} />}
                                                                value={currentEvent.department?.id}
                                                                filterOption={true}
                                                                optionFilterProp="label">
                                                                {this.props.departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                                                            </Select>
                                                        </Col>
                                                    </>
                                                    : null
                                            }
                                            {
                                                this.props.company?.isTimeClockEnabled ?
                                                    <>
                                                        <Col xs={{ span: 20 }}>
                                                            <span><FormattedMessage defaultMessage={'Disable the timeclock'} /></span>
                                                        </Col>
                                                        <Col xs={{ span: 4 }}>
                                                            <Switch
                                                                onChange={(checked) => this.dataChanged(EventDataType.IgnoreTimeClock, checked)}
                                                                checked={currentEvent.ignoreTimeClock}
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={<CloseOutlined />}
                                                            />
                                                        </Col>
                                                    </>
                                                    : null
                                            }
                                            <Divider style={{ margin: 0 }} type={'horizontal'} />
                                            <Col xs={{ span: 24 }}>
                                                <div className="flex-row">
                                                    <p><FormattedMessage defaultMessage={'Color'} /></p>
                                                    <ColorChooser
                                                        colors={this.props.settings.colors}
                                                        activeColor={currentEvent.color}
                                                        onChangeColor={(color) => this.dataChanged(EventDataType.Color, color?.id)}>

                                                    </ColorChooser>
                                                </div>
                                                {/* <Select
                                                    className="event-modal-data-entry"
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.Color, id)}
                                                    value={currentEvent.color?.id ? currentEvent.color?.id as number : -1}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {colors}
                                                </Select> */}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                );
            }
            case 1:
                return (
                    <Row gutter={[15, 15]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Card size="small"
                                title={<FormattedMessage defaultMessage={'Breaks ({count})'} values={{ count: currentEvent.breakTimes?.length || 0 }} />}
                                extra={[
                                    <CircleButton
                                        key={`eventmodal-breaktime-action-add`}
                                        icon={<PlusOutlined />}
                                        title={intl.formatMessage({ defaultMessage: 'Add a break' })}
                                        onClick={() => this.setState({
                                            addBreaktime: true, editBreakTime: {
                                                startDate: undefined,
                                                endDate: undefined,
                                            }
                                        })}
                                        small
                                    />
                                ]}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }}>
                                        {
                                            currentEvent.breakTimes && currentEvent.breakTimes.length > 0 ?
                                                <Collapse accordion={true}>
                                                    {
                                                        currentEvent.breakTimes.map((bt, index) => {
                                                            return (
                                                                <Panel header={bt.title} key={index + 1} extra={this.genExtra(index)}>
                                                                    <p><FormattedMessage defaultMessage={'Start'} />{':'} {bt.startDate.format(getFormat('DATE_AND_TIME_SHORT'))}</p>
                                                                    <p><FormattedMessage defaultMessage={'End'} />{':'}  {bt.endDate.format(getFormat('DATE_AND_TIME_SHORT'))}</p>
                                                                    <p><FormattedMessage defaultMessage={'Duration: {hours} ({totalHours}h)'} values={{ hours: momentDurationFormat(moment.duration(bt.totalHours, "hours")), totalHours: bt.totalHours }} /></p>
                                                                    <p>{bt.isPaid ? <FormattedMessage defaultMessage={"At the employer's expense"} /> : <FormattedMessage defaultMessage={"At employee's expense"} />}</p>
                                                                </Panel>
                                                            );
                                                        })
                                                    }
                                                </Collapse>
                                                :
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No planned breaks'} />} />
                                        }
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Row gutter={[15, 15]}>
                                {
                                    checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id, this.props.currentUser?.groupsAdmin?.length) ?
                                        <Col xs={{ span: 24 }}>
                                            <Card size="small" title={<FormattedMessage defaultMessage={'Travel & Vehicle'} />}>
                                                <Row gutter={[10, 10]}>
                                                    <Col xs={{ span: 10 }}>
                                                        <span><FormattedMessage defaultMessage={'Departure point'} /></span>
                                                    </Col>
                                                    <Col xs={{ span: 14 }}>
                                                        <Select
                                                            className="planning-modal-input-100"
                                                            showSearch
                                                            allowClear
                                                            disabled={this.props.basePois === undefined ? true : this.props.basePois.data === undefined ? true : this.props.basePois.data.length <= 0}
                                                            onChange={(id: number) => this.dataChanged(EventDataType.BasePoi, id)}
                                                            value={currentEvent.basePoi?.id}
                                                            filterOption={true}
                                                            optionFilterProp="label">
                                                            {this.props.basePois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                        </Select>
                                                    </Col>
                                                    <Col xs={{ span: 10 }}>
                                                        <span title={intl.formatMessage({ defaultMessage: 'Distance in km' })}><FormattedMessage defaultMessage={'Distance'} /></span>
                                                        <Tooltip title={<FormattedMessage defaultMessage={'Total distance of the outward journey, in Km'} />}>
                                                            <InfoCircleOutlined className="tab-tooltip-info" />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={{ span: 14 }}>
                                                        <div style={{ display: 'flex', gap: '4px' }}>
                                                            <InputNumber
                                                                title={this.state.distanceDuration ? intl.formatMessage({ defaultMessage: 'Travel time ~{time} minutes' }, { time: this.state.distanceDuration ? this.state.distanceDuration : '' }) : ''}
                                                                disabled={this.state.distanceDisabled || this.state.loadingDistance}
                                                                min={0}
                                                                max={1000}
                                                                id="planning-distance-input"
                                                                // className="planning-modal-input"
                                                                style={{ flexGrow: '1', minWidth: 'unset' }}
                                                                value={currentEvent.distance}
                                                                placeholder={intl.formatMessage({ defaultMessage: 'Distance in km' })}
                                                                onChange={e => this.dataChanged(EventDataType.Distance, e)} />
                                                            <Popconfirm
                                                                title={<FormattedMessage defaultMessage={'Do you want to generate the distance automatically according to the general conditions?'} />}
                                                                onConfirm={this.generateDistance}
                                                                okText={<FormattedMessage defaultMessage={'Yes'} />}
                                                                cancelText={<FormattedMessage defaultMessage={'No'} />}
                                                                placement="topRight"
                                                                disabled={this.state.distanceDisabled || this.state.loadingDistance}
                                                            >
                                                                <CircleButton
                                                                    small
                                                                    title={intl.formatMessage({ defaultMessage: 'Generate optimized distance' })}
                                                                    placement="top"
                                                                    disabled={this.state.distanceDisabled}
                                                                    icon={<Anticon icon={<RiPinDistanceFill />} />}
                                                                    loading={this.state.loadingDistance}
                                                                />
                                                            </Popconfirm>
                                                            <CircleButton
                                                                small
                                                                title={intl.formatMessage({ defaultMessage: 'Open Google Maps' })}
                                                                placement='top'
                                                                disabled={this.state.distanceDisabled}
                                                                icon={<FAIcon prefix='fad' name='map-location-dot' />}
                                                                loading={this.state.loadingDistance}
                                                                onClick={this.openGoogleMaps}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ span: 10 }}>
                                                        <span><FormattedMessage defaultMessage={'Vehicles'} /></span>
                                                    </Col>
                                                    <Col xs={{ span: 14 }}>
                                                        <Select
                                                            className="planning-modal-input-100"
                                                            showSearch
                                                            allowClear
                                                            onChange={(id: number) => this.dataChanged(EventDataType.Vehicle, id)}
                                                            value={currentEvent.vehicle?.id}
                                                            filterOption={true}
                                                            optionFilterProp="label">
                                                            {this.props.vehicles?.data?.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-tod-${t.id}`}>{t.name}</Select.Option>)}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        : null
                                }
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title="Actions">
                                        {
                                            canManage && this.state.isCreate &&
                                            <>
                                                <Checkbox
                                                    checked={this.state.asTemplate}
                                                    onChange={(e) => this.dataChanged(EventDataType.AsTemplate, e)}>
                                                    <FormattedMessage defaultMessage={'Add as template'} />
                                                </Checkbox>
                                                <Tooltip overlay={<FormattedMessage defaultMessage={'A template enables the creation of a new event based on its configuration'} />} placement="right">
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip><br />
                                            </>
                                        }
                                        <Checkbox
                                            checked={this.state.sendMessage}
                                            onChange={(e) => this.dataChanged(EventDataType.SendMessage, e)}>
                                            <FormattedMessage defaultMessage={'Send notification'} />
                                        </Checkbox>
                                        <Tooltip overlay={<FormattedMessage defaultMessage={'Send a notification message to the users concerned'} />} placement="right">
                                            <InfoCircleOutlined className="tab-tooltip-info" />
                                        </Tooltip>
                                        <div style={{ marginTop: 10 }}>
                                            {
                                                currentEvent.reminders && currentEvent.reminders.length > 0 ?
                                                    <Collapse className='__collapse-background-transparent reminder-collapse' bordered={true} accordion={true}>
                                                        {
                                                            currentEvent.reminders.sort((a, b) => a.minBeforeEventStart > b.minBeforeEventStart ? 1 : -1).map((reminder, index) => {
                                                                const user = this.props.users.find(u => u.id === reminder.sentTo);
                                                                const selectedDurationType = automaticDurationTypeSelection(reminder.minBeforeEventStart);
                                                                const isInThePast = moment().isAfter(currentEvent.startDate.clone().subtract(reminder.minBeforeEventStart, 'minutes'));
                                                                return (
                                                                    <Panel header={<span style={{ textDecoration: ((!reminder.retreived || !reminder.sent) && isInThePast) ? 'line-through solid red auto' : 'none' }}><FormattedMessage defaultMessage={'Reminder: {time} {type}'} values={{ time: reminder.minBeforeEventStart / selectedDurationType.lengthInMin, type: (reminder.minBeforeEventStart / selectedDurationType.lengthInMin) <= 1 ? intl.formatMessage(selectedDurationType.name).toLowerCase() : intl.formatMessage(selectedDurationType.pluralName).toLowerCase() }} /></span>} key={index + 1}
                                                                        extra={[
                                                                            <SpaceContent key={`space-reminder-button-${reminder.id}`}>
                                                                                <CircleButton onClick={(e) => { e.stopPropagation(); this.setState({ addReminder: true, editReminder: reminder }); }} disabled={reminder.retreived || reminder.sent} small key={`edit-reminder-button-${reminder.id}`} icon={<EditOutlined />} title={intl.formatMessage({ defaultMessage: 'Edit reminder' })} />
                                                                                <DeleteButton
                                                                                    disabled={reminder.retreived || reminder.sent}
                                                                                    small
                                                                                    key={`remove-reminder-button-${reminder.id}`}
                                                                                    title={intl.formatMessage({ defaultMessage: 'Remove reminder' })}
                                                                                    text={<FormattedMessage defaultMessage={'Do you want to delete this reminder?'} />}
                                                                                    onConfirm={e => {
                                                                                        e?.stopPropagation();
                                                                                        this.removeEventReminder(reminder);
                                                                                    }}
                                                                                    placement="left"
                                                                                />
                                                                            </SpaceContent>
                                                                        ]}>
                                                                        <p style={{ textDecoration: ((!reminder.retreived || !reminder.sent) && isInThePast) ? 'line-through solid red auto' : 'none' }}>
                                                                            <FormattedMessage defaultMessage={'Send a reminder {time} {type} before the event'} values={{ time: reminder.minBeforeEventStart / selectedDurationType.lengthInMin, type: (reminder.minBeforeEventStart / selectedDurationType.lengthInMin) <= 1 ? intl.formatMessage(selectedDurationType.name).toLowerCase() : intl.formatMessage(selectedDurationType.pluralName).toLowerCase() }} />
                                                                        </p>
                                                                        {(reminder.retreived && !reminder.sent) && <p><FormattedMessage defaultMessage={'The reminder is being sent'} /><div className='dots-loader' /></p>}
                                                                        {reminder.sent && <p><FormattedMessage defaultMessage={'Reminder was sent at {time} {user}'} values={{ time: reminder.modified?.format(getFormat('DATE_LONG_AND_ON_TIME_LONG')), user: user && intl.formatMessage({ defaultMessage: 'to user {user}' }, { user: `${user?.first_name} ${user?.last_name}` }) }} /></p>}
                                                                        {((!reminder.retreived || !reminder.sent) && isInThePast) && <p><FormattedMessage defaultMessage={'The reminder is set in the past and will not be sent.'} /></p>}

                                                                        {/* <p>Fin: {bt.endDate.format("DD.MM.YYYY HH:mm")}</p>
                                                                    <p>{`Durée: ${moment.duration(bt.totalHours, 'hours').format('mm')} min (${Math.round((bt.endDate.diff(bt.startDate, "hours", true) + Number.EPSILON) * 100) / 100}h)`}</p>
                                                                    <p>{bt.isPaid ? "À la charge de l'employeur." : "À la charge de l'employé."}</p> */}
                                                                    </Panel>
                                                                );
                                                            })
                                                        }
                                                        <Panel className='__collapse-main __collapse-normal' collapsible={'icon'} showArrow={false} header={<div onClick={(e) => { e.stopPropagation(); this.setState((state) => ({ addReminder: true, newReminderIndex: state.newReminderIndex - 1, editReminder: { id: state.newReminderIndex, minBeforeEventStart: 5, retreived: false, sent: false, created: moment() } })); }} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', gap: 8, alignItems: 'center' }}><PlusCircleOutlined /><FormattedMessage defaultMessage={'Add a reminder'} /></div>} key={`colapse-reminder-button`} />

                                                    </Collapse>
                                                    :
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span><FormattedMessage defaultMessage={'No scheduled reminder'} /></span>
                                                        <CircleButton onClick={() => this.setState((state) => ({ addReminder: true, newReminderIndex: state.newReminderIndex - 1, editReminder: { id: state.newReminderIndex, minBeforeEventStart: 5, retreived: false, sent: false, created: moment() } }))} size='small' icon={<PlusOutlined />} title={intl.formatMessage({ defaultMessage: 'Add a reminder' })} />
                                                    </div>
                                            }
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                );
        }
    };

    getCodeMessage = (code?: EventCopyErrorCodes) => {
        switch (code) {
            case 'NOT_IN_PERIODS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Date of copy outside mission period'} /></span><br /></>;
            case 'TOO_MANY_HOURS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Too many scheduled hours'} /></span><br /></>;
            case 'TOO_MANY_HOURS_IN_EVENT':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Too many hours scheduled in the event'} /></span><br /></>;
            case 'NOT_ENOUGH_HOURS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Not enough hours scheduled'} /></span><br /></>;
            case 'NOT_ENOUGH_HOURS_IN_EVENT':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Not enough hours scheduled in the event'} /></span><br /></>;
            case 'TOO_MANY_HUMANS':
                return <><span>{' - '}<FormattedMessage defaultMessage={'Overload of human needs for the mission'} /></span><br /></>;
            default:
                return <><span>{' - '}<FormattedMessage defaultMessage={'Unknown error'} /></span><br /></>;
        }
    };

    renderCreationErrors = () => {
        const { createErrors } = this.state;
        return (
            <div className='__mp-force-container'>
                <FormattedMessage defaultMessage={'Force copy'} />
                <Divider style={{ marginTop: 10 }} />
                {
                    createErrors.map((error, idx) => {
                        return (
                            <div key={`event-${idx}`} className='__mp-force-event-container'>
                                <div className='__mp-force-icons-container'>
                                    <div className='__mp-force-icon'>
                                        {
                                            error.errors.findIndex(e => e.type && e.type === 'unavailabilities') !== -1 ?
                                                <Tooltip
                                                    title={<FormattedMessage defaultMessage={'User not available'} />}
                                                >
                                                    <FAIcon name='user-slash' prefix='fad' className='__mp-force-icon-fa' color={'black'} />
                                                </Tooltip>
                                                :
                                                <FAIcon name='user-slash' prefix='fad' className='__mp-force-icon-fa' color={'#ececec'} />
                                        }
                                    </div>
                                    <div className='__mp-force-icon'>
                                        {
                                            error.errors.findIndex(e => e.type && e.type === 'overlap' || e.type && e.type === 'new-events-overlap') !== -1 ?
                                                <Tooltip
                                                    title={<FormattedMessage defaultMessage={'Event overlap'} />}
                                                >
                                                    <FAIcon name='circles-overlap' prefix='fad' className='__mp-force-icon-fa' color={'black'} />
                                                </Tooltip>
                                                :
                                                <FAIcon name='circles-overlap' prefix='fad' className='__mp-force-icon-fa' color={'#ececec'} />
                                        }
                                    </div>
                                    <div className='__mp-force-icon'>
                                        {
                                            error.errors.findIndex(e => e.type && e.type === 'missionMissmatch') !== -1 ?
                                                <Tooltip
                                                    title={
                                                        <>
                                                            <p><FormattedMessage defaultMessage={'CRM Error'} /></p>
                                                            {error.errors[error.errors.findIndex(e => e.type && e.type === 'missionMissmatch')].codes?.map(code => this.getCodeMessage(code))}
                                                        </>
                                                    }

                                                >
                                                    <FAIcon name='user-gear' prefix='fad' className='__mp-force-icon-fa' color={'black'} />
                                                </Tooltip>
                                                :
                                                <FAIcon name='user-gear' prefix='fad' className='__mp-force-icon-fa' color={'#ececec'} />
                                        }
                                    </div>
                                </div>
                                <div className='__mp-force-event' style={{ background: 'var(--primary-color)' }}>
                                    <div className='__mp-force-event-title' style={{ color: colorIsBright('#bea156') ? 'black' : 'white' }}>{`${error.user.first_name} ${error.user.last_name}`}</div>
                                </div>
                            </div>
                        );
                    })
                }
                <br />
                <p><strong><FormattedMessage defaultMessage={"Do you really want to force the creation of the event?"} /></strong></p>
            </div>
        );
    };

    renderClockError = () => {
        const { isCreate } = this.state;
        return (
            <>
                <p style={{ fontWeight: 'bolder' }}>
                    {isCreate ? <FormattedMessage defaultMessage={'Force create'} /> : <FormattedMessage defaultMessage={'Force edit'} />}
                </p>
                <p>
                    <FormattedMessage defaultMessage={'will create the event despite the lack of availability of the selected user'} />
                </p>
            </>
        );
    };

    render() {
        const { currentEvent, isCreate, editBreakTime, createErrors } = this.state;
        const { intl } = this.props;
        const title = isCreate ? <FormattedMessage defaultMessage={'Add an event'} /> : <FormattedMessage defaultMessage={'Edit event {title}'} values={{ title: currentEvent.title }} />;
        return (
            currentEvent ?
                <Modal
                    key={this.props.modalKey}
                    style={{ top: '50px' }}
                    width="880px"
                    className="planning-modal"
                    title={title}
                    open={Boolean(currentEvent)}
                    onCancel={this.onCancel}
                    destroyOnClose={true}
                    footer={[
                        <Button type="dashed" onClick={this.onCancel} key="planning-modal-cancel-button">
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <div key="planning-modal-next-previous-buttons">
                            <Button disabled={this.state.currentStep === 0} onClick={() => this.setState({ currentStep: this.state.currentStep - 1 })}>
                                <LeftOutlined />
                                <span className="modal-button-next-previous-title"><FormattedMessage defaultMessage={'Previous'} /></span>
                            </Button>
                            <Button disabled={this.state.currentStep === 1 || this.state.errorWithDates} onClick={() => this.setState({ currentStep: this.state.currentStep + 1 })}>
                                <span className="modal-button-next-previous-title"><FormattedMessage defaultMessage={'Next'} /></span>
                                <RightOutlined />
                            </Button>
                        </div>,
                        currentEvent.clocked && !currentEvent.clocked.isRefused && !currentEvent.ignoreTimeClock ?
                            <Popconfirm
                                open={this.state.popConfirmAvailabilityError || createErrors.length > 0}
                                title={
                                    createErrors.length > 0 ?
                                        this.renderCreationErrors()
                                        :
                                        this.renderClockError()
                                }
                                okText={isCreate ? <FormattedMessage defaultMessage={'Force create'} /> : <FormattedMessage defaultMessage={'Force edit'} />}
                                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                okButtonProps={{ type: 'link' }}
                                cancelButtonProps={{ type: 'link' }}
                                icon={<WarningOutlined style={{ color: '#ff2626' }} />}
                                placement={'topRight'}
                                onConfirm={this.onForceConfirm}
                                onCancel={() => this.setState({ popConfirmAvailabilityError: false })}>
                                <Popconfirm
                                    title={<FormattedMessage defaultMessage={'Confirming this modification will adjust the current event hours and mark them as edited by the administrator.'} />}
                                    okText={<FormattedMessage defaultMessage={'Confirm modification'} />}
                                    cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                    okButtonProps={{ type: 'link' }}
                                    cancelButtonProps={{ type: 'link' }}
                                    icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                                    placement={'topRight'}
                                    onConfirm={() => this.onClickBeforConfirm()}
                                    onCancel={(e) => e?.stopPropagation()}>
                                    <Button disabled={this.state.errorWithDates || this.state.errorWithUsers} id="planning-modal-ok-button" type="primary" onClick={(e) => e.stopPropagation()} loading={this.props.loading} key="planning-modal-button-ok">
                                        <FormattedMessage defaultMessage={'Save and modify the timeclock'} />
                                    </Button>
                                </Popconfirm>
                            </Popconfirm>

                            :
                            <Popconfirm
                                open={createErrors.length > 0}
                                title={
                                    this.renderCreationErrors()
                                }
                                okText={isCreate ? <FormattedMessage defaultMessage={'Force create'} /> : <FormattedMessage defaultMessage={'Force edit'} />}
                                cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                okButtonProps={{ type: 'link' }}
                                cancelButtonProps={{ type: 'link' }}
                                placement={'topRight'}
                                onConfirm={this.onForceConfirm}
                                icon={<WarningOutlined style={{ color: '#ff2626' }} />}
                                onCancel={() => this.setState({ popConfirmAvailabilityError: false, createErrors: [] })}>
                                <Button
                                    disabled={this.state.errorWithDates || this.state.errorWithUsers || (isCreate && this.state.errorWithSelectedUsers.state) || this.state.loadingFromTemplate}
                                    id="planning-modal-ok-button"
                                    type="primary"
                                    onClick={() => this.onClickBeforConfirm()}
                                    loading={this.props.loading}
                                    key="planning-modal-button-ok"
                                >
                                    {isCreate ? <FormattedMessage defaultMessage={'Add'} /> : <FormattedMessage defaultMessage={'Save'} />}
                                </Button>
                            </Popconfirm>
                    ]}>
                    {
                        <Steps
                            type="navigation"
                            size="small"
                            current={this.state.currentStep}
                            onChange={(current) => this.setState({ currentStep: current })}
                            style={{ marginBottom: '20px', boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}>
                            <Steps.Step title={<FormattedMessage defaultMessage={'Basic'} />} key="planning-modal-step-0" />
                            <Steps.Step disabled={this.state.errorWithDates} title={<FormattedMessage defaultMessage={'Advanced'} />} key="planning-modal-step-1" />
                        </Steps>
                    }
                    <Spin spinning={this.state.loadingFromTemplate}>
                        {this.renderModalContent()}
                    </Spin>
                    <Modal
                        width="400px"
                        style={{ top: "150px" }}
                        title={editBreakTime.id ? <FormattedMessage defaultMessage={'Edit a break: {title}'} values={{ title: editBreakTime.title }} /> : <FormattedMessage defaultMessage={'Add a break'} />}
                        open={this.state.addBreaktime}
                        onCancel={() => this.setState({ addBreaktime: false })}
                        destroyOnClose={true}
                        footer={[
                            // <Button type="dashed" onClick={() => this.setState({ addBreaktime: false })} key="breaktime-modal-cancel-button">
                            //     {'Annuler'}
                            // </Button>,
                            <div key={`eventmodal-add-breaktime-action`} style={{ display: 'flex', justifyContent: editBreakTime.id === undefined ? 'space-between' : 'flex-end' }}>
                                {
                                    editBreakTime.id === undefined ?
                                        <Button title={intl.formatMessage({ defaultMessage: 'Save the pause and add another' })} type="default" onClick={this.validateBreakTime} disabled={this.state.editBreakTimeError || this.checkBreaktimeFields()} key="breaktime-modal-button-ok">
                                            <FormattedMessage defaultMessage={'Add and continue'} />
                                        </Button>
                                        : null
                                }
                                <Button type="primary" onClick={() => { this.validateBreakTime(); this.setState({ addBreaktime: false }); }} disabled={this.state.editBreakTimeError || this.checkBreaktimeFields()} key="breaktime-modal-button-ok">
                                    {editBreakTime.id ? <FormattedMessage defaultMessage={'Edit'} /> : <FormattedMessage defaultMessage={'Add and close'} />}
                                </Button>
                            </div>
                        ]}>
                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }}>
                                <InputField
                                    className={this.state.editBreakTimeError === true && editBreakTime.title === undefined ? "planning-modal-input-error" : ""}
                                    value={editBreakTime.title}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                                    onChange={(e: InputFieldOnChangeEvent) => {
                                        editBreakTime.title = e.target.value;
                                        editBreakTime.title === "" && (editBreakTime.title = undefined);
                                        this.setState({ editBreakTime });
                                    }} />
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <DateTimePicker
                                    valueFrom={editBreakTime.startDate}
                                    defaultValueFrom={currentEvent.startDate.clone()}
                                    minFrom={currentEvent.startDate.clone()}
                                    maxFrom={currentEvent.endDate.clone()}
                                    onChangeFrom={(e) => {
                                        if (e) editBreakTime.startDate = e;
                                        this.setState({ editBreakTime });
                                    }}
                                    placeHolderDateFrom={intl.formatMessage({ defaultMessage: 'Start date' })}
                                    placeHolderTimeFrom={intl.formatMessage({ defaultMessage: 'Start time' })}
                                    formatTimeFrom={getFormat('TIME_SHORT')}
                                    timeViewFrom={["hours", "minutes"]}
                                    disableDateFrom={(date) => date.isBefore(currentEvent.startDate, 'days') || date.isAfter(currentEvent.endDate, 'days')}
                                    // disableTimeFrom={this.disableTime}

                                    valueTo={editBreakTime.endDate}
                                    defaultValueTo={currentEvent.startDate.clone()}
                                    minTo={currentEvent.startDate.clone()}
                                    maxTo={currentEvent.endDate.clone()}
                                    onChangeTo={(e) => {
                                        if (e) editBreakTime.endDate = e;
                                        this.setState({ editBreakTime });
                                    }}

                                    placeHolderDateTo={intl.formatMessage({ defaultMessage: 'End date' })}
                                    placeHolderTimeTo={intl.formatMessage({ defaultMessage: 'End time' })}
                                    formatTimeTo={getFormat('TIME_SHORT')}
                                    timeViewTo={["hours", "minutes"]}
                                    disableDateTo={(date) => date.isBefore(currentEvent.startDate, 'days') || date.isAfter(currentEvent.endDate, 'days')}
                                    // disableTimeTo={this.disableTime}

                                    showTime={true}
                                    type={DateTimePickerType.RANGE}
                                    version={DateTimePickerVersion.FIELD}
                                    setError={(error) => this.setState({ editBreakTimeError: error })}
                                />
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <Checkbox
                                    checked={editBreakTime.isPaid}
                                    onChange={(e) => {
                                        editBreakTime.isPaid = e.target.checked;
                                        this.setState({ editBreakTime });
                                    }}>
                                    <FormattedMessage defaultMessage={'Break paid by the employer'} />
                                </Checkbox>
                                <Tooltip overlay={<FormattedMessage defaultMessage={'If the break is paid for by the employer, it will not be deducted from the duration of the event'} />} placement="right">
                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                </Tooltip>
                            </Col>
                            {/* <Col style={{ textAlign: 'center' }} xs={{ span: 24 }}>
                                <Button icon={<ArrowDownOutlined />} disabled={this.state.editBreakTimeError || this.checkBreaktimeFields()} type="primary" style={{ minWidth: "150px" }} onClick={this.validateBreakTime} loading={this.props.loading}>
                                    {"Ajouter"}
                                </Button>
                            </Col> */}
                        </Row>
                    </Modal>
                    <UploadModal
                        eventId={Number(this.state.currentEvent.id)}
                        type={this.state.addDocuments ? 'FLE' : this.state.addImages ? 'IMG' : 'VDO'}
                        title={this.state.addDocuments ? intl.formatMessage({ defaultMessage: 'Add documents' }) : this.state.addImages ? intl.formatMessage({ defaultMessage: 'Add images' }) : undefined}
                        onClose={(cancel?: boolean) => this.setState((state) => ({ addDocuments: false, addImages: false, filesToValidate: cancel ? [] : state.filesToValidate, imagesToValidate: cancel ? [] : state.imagesToValidate }))}
                        open={this.state.addDocuments || this.state.addImages}
                        files={this.state.files}
                        addFiles={(files) => this.setState({ files: files })}
                        filesToValidate={this.state.addDocuments ? this.state.filesToValidate : this.state.addImages ? this.state.imagesToValidate : []}
                        setFilesToValidate={(newFilesToValidate: ValidateFile[]) => {
                            if (this.state.addDocuments) {
                                this.setState({ filesToValidate: newFilesToValidate.filter(f => f.type === 'ValidateFile' && f.res_type === 'FLE') });
                            } else if (this.state.addImages) {
                                this.setState({ imagesToValidate: newFilesToValidate.filter(f => f.type === 'ValidateFile' && f.res_type === 'IMG') });
                            }
                        }}
                    />
                    <ReminderModal
                        event={this.state.currentEvent}
                        onClose={() => this.setState({ addReminder: false, editReminder: undefined })}
                        open={this.state.addReminder}
                        reminder={this.state.editReminder}
                        onSave={(reminder: Reminder) => this.saveEventReminder(reminder)}
                    />
                </Modal>
                : null

        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    toggleLoading: (b: boolean) => dispatch(toggleLoading(b)),
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
    loadTypesOfVehicle: (forceReload?: boolean) => dispatch(loadTypesOfVehicle(forceReload)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),
});

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    currentUser: state.user.currentUser,
    isSmartphone: state.window.isSmartphone,
    settings: state.planning.settings,
    templates: state.planning.templates,
    typesOfDay: state.configurations.typesOfDay,
    typesOfDayOff: state.configurations.typesOfDayOff,
    project: state.configurations.project,
    vehicles: state.configurations.vehicles,
    basePois: state.location.basePois,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    pois: state.location.pois,
    departments: state.configurations.departments

});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(EditEventModal));